import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Spinner, Tooltip } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity, updateEntity, createEntity, reset } from './consultation.reducer';
import { getEntity as getPatient } from 'app/entities/patient/patient.reducer';
import ConsultationImageInput from './consultation-image-input';
import { convertDateFromServer, displayDefaultDate } from 'app/shared/util/date-utils';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { predict } from './prediction.reducer';
import { AUTHORITIES } from 'app/config/constants';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from 'app/shared/component/topbar';
import axios from 'axios';
import { toast } from 'react-toastify';

export const ConsultationUpdate = () => {
  const dispatch = useAppDispatch();
  const authorities = useAppSelector(state => state.authentication.account.authorities);

  const navigate = useNavigate();

  const { consultationId } = useParams<'consultationId'>();
  const { patientId } = useParams<'patientId'>();
  const isNew = consultationId === undefined;

  const patient = useAppSelector(state => state.patient.entity);
  const consultationEntity = useAppSelector(state => state.consultation.entity);
  const loading = useAppSelector(state => state.consultation.loading);
  const updateSuccess = useAppSelector(state => state.consultation.updateSuccess);
  const predictionSuccess = useAppSelector(state => state.prediction.updateSuccess);
  const predictionLoading = useAppSelector(state => state.prediction.loading);
  const [predictionRequested, setPredictionRequested] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [totalCount, setTotalCount] = useState(null);

  const handleClose = () => {
    if (predictionRequested) {
      setPredictionRequested(false);
      navigate(`/patient/${patientId}/consultation/${consultationEntity.id}` + location.search);
    } else {
      navigate(`/patient/${patientId}/consultation/${consultationEntity.id}/edit` + location.search);
    }
  };

  const sections = ['18','17','16','15 14','13 12','11 21','22 23','24 25','26','27','28',
    '48','47','46','45 44','43 42','41 31','32 33','34 35','36','37','38'];
  const [xRays, setXRays] = useState(
    sections.reduce((acc, section) => ({ ...acc, [section]: null }), {}));
  const [panoramic, setPanoramic] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [allowPredict, setAllowPredict] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isNew) {
      dispatch(getPatient(patientId));
      dispatch(reset());
    } else {
      dispatch(getEntity({patientId, consultationId}));
    }
  }, []);

  useEffect(() => {
    if (consultationEntity?.images) {
      const newXRays = sections.reduce((acc, section) => ({ ...acc, [section]: null }), {});
      const newPhotos = [];
      let predictionMissing = false;
      consultationEntity.images.forEach(image => {
        if(image.imageDiagnostic === null){
          predictionMissing = true;
        }
        if (image.type === 'panoramic') {
          setPanoramic(image);
        } else if (image.type === 'xray') {
          const section = image.section;
          newXRays[section] = image;
        } else if (image.type === 'photo') {
          newPhotos.push(image);
        }
      });
  
      setXRays(newXRays);
      setPhotos(newPhotos);
      setAllowPredict(predictionMissing);
      setMounted(true);
    } else {
      setMounted(false);
    }
  }, [consultationEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (predictionSuccess) {
      handleClose();
    }
  }, [predictionSuccess]);

  useEffect(() => {
    if (!predictionLoading) {
      setPredictionRequested(false);
    }
  }, [predictionLoading]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    const date = new Date(values.date)
    const isoDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();
    const entity = {
      patient: patient,
      ...consultationEntity,
      ...values,
      date: isoDate,
    };

    if (isNew) {
      dispatch(createEntity({patientId, entity}));
    } else {
      dispatch(updateEntity({patientId, entity}));
    }
  };

  const handlePredictionRequest = () => {
    setPredictionRequested(true);
  }

  const fetchStream = async () => {
    let success = 0;
    const imagesToProcess = consultationEntity.images.filter(image => image.imageDiagnostic === null);
    setTotalCount(imagesToProcess.length);
  
    try {
      for (const image of imagesToProcess) {
        const response = await fetch(`api/patients/${patientId}/consultations/${consultationId}/predict/${image.id}`);
        if (!response.ok) {
          toast.error(translate("dfaiApp.consultation.modelUnavailable"));
          break; // Exit the loop on error
        }
        setProgress(progress => progress + 1); // Increment progress
        success += 1;
      }
    } catch (error) {
      console.error("Error processing image:", error);
    } finally {
      setPredictionRequested(false); // Ensure this is called whether successful or not
      toast.success(`${success}/${imagesToProcess.length}`);
      navigate(`/patient/${patientId}/consultation/${consultationId}`);
    }
  };

  useEffect(() => {
    if(predictionRequested) {
      setProgress(0);
      fetchStream();
    }
  }, [predictionRequested]);

  const refreshEntities = () => {
    setAllowPredict(false);
    dispatch(getEntity({patientId, consultationId}));
  }


  const defaultValues = () =>
    isNew
      ? {date: displayDefaultDate()}
      : {
          ...consultationEntity,
          practitioner: consultationEntity.practitioner ? consultationEntity.practitioner.id : null,
          date: convertDateFromServer(consultationEntity.date)
        };

  return (
    <div>
      <Row className="justify-content-center">
        <div className='my-2'>
          <ProgressBar loading={predictionRequested} progress={progress} total={totalCount}/>
        </div>
        <Col md="4">
          <h2 id="dfaiApp.consultation.home.createOrEditLabel" data-cy="ConsultationCreateUpdateHeading">
            { isNew ?
              <Translate contentKey="dfaiApp.consultation.home.createLabel">Create or edit a Consultation</Translate>
              :
              <Translate contentKey="dfaiApp.consultation.home.existingConsultationHeader" interpolate={{date: convertDateFromServer(consultationEntity.date)}}>Consultation details</Translate>
            }
          </h2>
        </Col>
        <Col md="2">
          <div className='d-flex'>
            { hasAnyAuthority(authorities, [AUTHORITIES.UPDATE_CONSULTATION]) && consultationEntity?.id &&
              <div  className="mx-2">
                <Button onClick={handlePredictionRequest} size="lg" replace color="predict" disabled={!allowPredict} tool data-cy="entityDetailsReportButton" id="predict-button">
                  <FontAwesomeIcon icon={faRobot} />
                  &nbsp;
                  <Translate contentKey="dfaiApp.consultation.predict">Predict</Translate>
                </Button>
                {mounted && consultationEntity?.id && <Tooltip
                  placement="top"
                  isOpen={!allowPredict}
                  target="predict-button"
                >
                  <Translate contentKey="dfaiApp.consultation.predictionsComplete">All images are processed</Translate>
                </Tooltip>}
              </div>}
            <Button tag={Link} size="lg" id="cancel-save" data-cy="entityCreateCancelButton" to={consultationId ? `/patient/${patientId}/consultation/${consultationId}` : `/patient/${patientId}`} replace color="info">
              <FontAwesomeIcon icon="arrow-left" />
              &nbsp;
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
          </div>
        </Col>
      </Row>
      { isNew && <Row className="justify-content-center">
        <Col md="6">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  hidden
                  id="consultation-id"
                  label={translate('dfaiApp.consultation.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                id="consultation-date"
                name="date"s
                data-cy="date"
                readOnly={!isNew}
                type="date"
                label={translate('dfaiApp.consultation.date')}
                placeholder="DD-MM-YYYY"
              />
              &nbsp;
              <div className="d-flex justify-content-end">
                {isNew && <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={loading}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>}
              </div>
            </ValidatedForm>
          )}
        </Col>
      </Row>}
      { !isNew && <Row>
        <Row>
          <h3 className="font-bold text-2xl mb-6">Choose the files for prediction:</h3>
          <h2 className="text-lg font-bold mb-6">Single X-ray</h2>
        </Row>
        <Row>
        {Array.from({ length: Math.ceil(Object.entries(sections).length / 11) }, (_, rowIndex) => (
          <Row key={rowIndex}>
            {sections.slice(rowIndex * 11, rowIndex * 11 + 11).map(section => (
              <Col key={section} md="1">
                <ConsultationImageInput
                  id={section}
                  section={section}
                  type="xray"
                  patientId={patientId}
                  image={xRays[section]}
                  consultationId={consultationId}
                  uploadCallback={refreshEntities}
                />
                <div className="text-sm text-center">{section}</div>
              </Col>
            ))}
          </Row>
        ))}
        </Row>
        <Row>
          <hr className="mt-8" />
          <Col md="3">
            <div className="flex gap-3">
              <div className="flex-1 border-r pr-16 py-8">
                <h2 className="text-lg font-bold mb-6">Panoramic</h2>
                  <ConsultationImageInput
                    id="panoramic"
                    section="panoramic"
                    type="panoramic"
                    patientId={patientId}
                    image={panoramic}
                    consultationId={consultationId}
                    uploadCallback={refreshEntities}
                  />
              </div>
            </div>
          </Col>
          <Col md="9">
            <div className="flex-1 mb-6 pl-16 py-8 flex flex-col">
              <h2 className="text-lg font-bold mb-6">Photos</h2>
              <Row>
                <Col key={photos.length + 1} md="2">
                    <ConsultationImageInput
                      id={`empty-photo-input`}
                      section="photo"
                      type="photo"
                      image={null}
                      patientId={patientId}
                      consultationId={consultationId}
                      uploadCallback={refreshEntities}
                    />
                  </Col>
                {photos.map((photo,index) => (
                  <Col key={photo} md="2">
                    <ConsultationImageInput
                      id={`photo-${index + 1}`}
                      section="photo"
                      type="photo"
                      patientId={patientId}
                      image={photo}
                      consultationId={consultationId}
                      uploadCallback={refreshEntities}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </Row>}
    </div>
  );
};

export default ConsultationUpdate;
