import { IUser } from 'app/shared/model/user.model';
import { ProfessionalStatus } from 'app/shared/model/enumerations/professional-status.model';
import { IClinic } from './clinic.model';

export interface IProfessional {
  id?: string;
  status?: Array<keyof typeof ProfessionalStatus> | [];
  corporateName?: string | null;
  address?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  website?: string | null;
  rpps?: string | null;
  siret?: string | null;
  firstName?: string | null,
  lastName?: string | null,
  user?: IUser | null;
  clinics?: IClinic[] | null;
}

export const defaultValue: Readonly<IProfessional> = {};
