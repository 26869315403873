import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, miniSerializeError } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState } from 'app/shared/reducers/reducer.utils';
import { IConsultation, defaultValue } from 'app/shared/model/consultation.model';
import { IImage } from 'app/shared/model/image.model';
import { IReport } from 'app/shared/model/report.model';
import { useAppSelector } from 'app/config/store';

const initialState: EntityState<IConsultation> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  overview: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

const apiUrl = (patientId) => `api/patients/${patientId}/consultations`;

// Actions

export const getEntities = createAsyncThunk('consultation/fetch_entity_list', async ({params, patientId}: { params: IQueryParams; patientId: string}) => {
  const requestUrl = `${apiUrl(patientId)}?${params.sort ? `page=${params.page}&size=${params.size}&sort=${params.sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<IConsultation[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'consultation/fetch_entity',
  async ({patientId, consultationId}: {patientId: string; consultationId: string}) => {
    const requestUrl = `api/patients/${patientId}/consultations/${consultationId}`;
    return axios.get<IConsultation>(requestUrl);
  },
  { serializeError: miniSerializeError },
);

export const getEntityReport = createAsyncThunk(
  'consultation/fetch_entity_report',
  async ({patientId, consultationId, locale}: {patientId: string; consultationId: string, locale: string}) => {
    const requestUrl = `api/patients/${patientId}/consultations/${consultationId}/reports?language=${locale}`;
    return axios.get<IReport>(requestUrl);
  },
  { serializeError: miniSerializeError },
);

export const getOverviewEntityReport = createAsyncThunk(
  'consultation/fetch_overview_entity_report',
  async ({patientId, locale}: {patientId: string; locale: string}) => {
    const requestUrl = `api/patients/${patientId}/consultations/overview/reports?language=${locale}`;
    return axios.get<IReport>(requestUrl);
  },
  { serializeError: miniSerializeError },
);

export const getConsultationsOverview = createAsyncThunk(
  'consultation/fetch_overview',
  async (patientId : string) => {
    const requestUrl = `api/patients/${patientId}/consultations/overview`;
    return axios.get<IConsultation>(requestUrl);
  },
  { serializeError: miniSerializeError },
);

export const getConsultationImage = createAsyncThunk(
  'consultation/fetch_image',
  async ({patientId, image}: {patientId: string; image: IImage}) => {
    const requestUrl = `${apiUrl(patientId)}/images/${image.id}/file`;
    const response = await axios.get(requestUrl, { responseType: 'blob' });
    return response.data;
  },
  { serializeError: miniSerializeError },
);

export const createEntity = createAsyncThunk(
  'consultation/create_entity',
  async ({ patientId, entity }: { patientId: string; entity: IConsultation }, thunkAPI) => {
    const result = await axios.post<IConsultation>(apiUrl(patientId), cleanEntity(entity));
    thunkAPI.dispatch(getEntities({params: {}, patientId: patientId}));
    return result;
  },
  { serializeError: miniSerializeError },
);

export const updateEntity = createAsyncThunk(
  'consultation/update_entity',
  async ({ patientId, entity }: { patientId: string; entity: IConsultation }, thunkAPI) => {
    const result = await axios.put<IConsultation>(`${apiUrl(patientId)}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({params: {}, patientId: patientId}));
    return result;
  },
  { serializeError: miniSerializeError },
);

export const partialUpdateEntity = createAsyncThunk(
  'consultation/partial_update_entity',
  async ({ patientId, entity }: { patientId: string; entity: IConsultation }, thunkAPI) => {
    const result = await axios.patch<IConsultation>(`${apiUrl(patientId)}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({params: {}, patientId: patientId}));
    return result;
  },
  { serializeError: miniSerializeError },
);

export const deleteEntity = createAsyncThunk(
  'consultation/delete_entity',
  async ({patientId, consultationId}: {patientId: string; consultationId: string}, thunkAPI) => {
    const requestUrl = `${apiUrl(patientId)}/${consultationId}`;
    const result = await axios.delete<IConsultation>(requestUrl);
    thunkAPI.dispatch(getEntities({params: {}, patientId: patientId}));
    return result;
  },
  { serializeError: miniSerializeError },
);

export const updateDentalStatus = createAsyncThunk(
  'consultation/update_dental_status',
  async ({ patientId, dentalStatus }: { patientId: string; dentalStatus: object }) => {
    const requestUrl = `api/patients/${patientId}/dental-status`;
    return axios.post(requestUrl, dentalStatus);
  },
  { serializeError: miniSerializeError }
);

// slice

export const ConsultationSlice = createEntitySlice({
  name: 'consultation',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getEntityReport.fulfilled, (state, action) => {
        state.loading = false;
        state.entity.report = action.payload.data;
      })
      .addCase(getOverviewEntityReport.fulfilled, (state, action) => {
        state.loading = false;
        state.entity.report = action.payload.data;
        console.log(state.entity.report);
      })
      .addCase(getConsultationsOverview.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity.report.recommendations = action.payload.data.report.recommendations;
      })
      .addMatcher(isPending(getEntities, getEntity, getEntityReport, getOverviewEntityReport, getConsultationsOverview), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ConsultationSlice.actions;

// Reducer
export default ConsultationSlice.reducer;
