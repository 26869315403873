import CircleIcon from 'app/shared/component/circle-icon';
import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { Col } from 'reactstrap';
import { colorPathologyMap } from '../../consultation-utils';

const PathologyPredictionGroup = ({
  pathologyName,
  pathologyPredictions,
  clinicPathologies,
  imageType,
  onFilterUpdated,
  onPathologyPredictionSelected,
  selectedPredictionId = null
}) => {

  const handleFilterChange = (event) => {
    const newValue = Number(event.target.value);
    onFilterUpdated(pathologyName, newValue);
  };

  const getTreshold = () => {
    const pathology = clinicPathologies.find(p => p.name === pathologyName);
    if(pathology) {
      if (imageType === "panoramic") {
        return pathology.aiPanoramicTreshold;
      } else if (imageType === "xray") {
        return pathology.aiXrayTreshold;
      } else if (imageType === "photo") {
        return pathology.aiPhotoTreshold;
      }
    } else {
      return 0;
    }
  }

  return (
    <div>
      <div>
        <Col>
          <div className="d-flex flex-wrap align-items-center">
            <CircleIcon color={colorPathologyMap.hasOwnProperty(pathologyName) ? colorPathologyMap[pathologyName] : "white"}/>
            <span className="m-0" style={{fontSize: "1rem"}}>&nbsp;<b>{translate(`dfaiApp.pathology.${pathologyName}`)}</b>&nbsp;</span>
            {clinicPathologies && <span> {` < ${getTreshold()}`}</span>}
          </div>
          <input
            type="range"
            id="filterRange"
            min="0"
            max="1"
            step="0.01"
            value={getTreshold()}
            onChange={handleFilterChange}
            className="mt-1 slider"
          />
        </Col>
      </div>
        <div className="d-inline-flex flex-wrap mx-2">
            {pathologyPredictions?.map((pathologyPrediction, index) => (
                <div key={index} className="clickable-row" onClick={() => onPathologyPredictionSelected(pathologyPrediction)}>
                    <div className={`pe-auto m-1 ${selectedPredictionId === pathologyPrediction.id ? "selected-row" : "selectable-row"}`}>
                        <div className="d-inline-flex align-items-center" style={{fontSize: "0.9rem"}}>
                            <span className="me-2 text-nowrap w-auto">
                              {pathologyPrediction.teeth?.startsWith("0") ? 
                                <Translate contentKey="dfaiApp.pathologyPrediction.sextant" />
                                :
                                <Translate contentKey="dfaiApp.pathologyPrediction.teeth" />}
                                &nbsp;{pathologyPrediction.teeth}:
                            </span>
                            <div className="d-inline-flex">
                              {pathologyPrediction.confidence.map((c, i) => (
                                <div key={i} className={`badge ${c >= getTreshold() ? 'bg-green' : 'bg-warning'} me-1`}>
                                {Math.round(c*100)/100}
                                </div>
                              ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
  );
};

export default PathologyPredictionGroup;
