import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Matching from './matching';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

const MatchingRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={
      <PrivateRoute hasAnyAuthorities={[AUTHORITIES.VIEW_MATCHING]}>
        <Matching />
      </PrivateRoute>} />
  </ErrorBoundaryRoutes>
);

export default MatchingRoutes;
