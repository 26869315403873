import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './image-diagnostic.reducer';

export const ImageDiagnosticDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const imageDiagnosticEntity = useAppSelector(state => state.imageDiagnostic.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="imageDiagnosticDetailsHeading">
          <Translate contentKey="dfaiApp.imageDiagnostic.detail.title">ImageDiagnostic</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="dfaiApp.imageDiagnostic.id">Id</Translate>
            </span>
          </dt>
          <dd>{imageDiagnosticEntity.id}</dd>
          <dt>
            <span id="raw">
              <Translate contentKey="dfaiApp.imageDiagnostic.raw">Raw</Translate>
            </span>
          </dt>
          <dd>{imageDiagnosticEntity.raw}</dd>
          <dt>
            <span id="model">
              <Translate contentKey="dfaiApp.imageDiagnostic.model">Model</Translate>
            </span>
          </dt>
          <dd>{imageDiagnosticEntity.model}</dd>
          <dt>
            <span id="modelVersionId">
              <Translate contentKey="dfaiApp.imageDiagnostic.modelVersionId">Model Version Id</Translate>
            </span>
          </dt>
          <dd>{imageDiagnosticEntity.modelVersionId}</dd>
          <dt>
            <span id="deployedModelId">
              <Translate contentKey="dfaiApp.imageDiagnostic.deployedModelId">Deployed Model Id</Translate>
            </span>
          </dt>
          <dd>{imageDiagnosticEntity.deployedModelId}</dd>
          <dt>
            <span id="modelDisplayName">
              <Translate contentKey="dfaiApp.imageDiagnostic.modelDisplayName">Model Display Name</Translate>
            </span>
          </dt>
          <dd>{imageDiagnosticEntity.modelDisplayName}</dd>
        </dl>
        <Button tag={Link} to="/image-diagnostic" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/image-diagnostic/${imageDiagnosticEntity.id}/edit`} replace color="info">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ImageDiagnosticDetail;
