import { IQuote } from 'app/shared/model/quote.model';

export interface IAct {
  id?: string;
  code?: string | null;
  description?: string | null;
  toothMin?: number | null;
  toothMan?: number | null;
  sextantMin?: number | null;
  allowMissingTeeth?: boolean | null;
  allowAnyway?: boolean | null;
  bridge?: boolean | null;
  type?: string | null;
  maxMissingTeeth?: number | null;
  minMissingTeeth?: number | null;
}

export const defaultValue: Readonly<IAct> = {};
