import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, JhiPagination, JhiItemCount, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './consultation.reducer';
import { convertDateFromServer } from 'app/shared/util/date-utils';

export const Consultation = () => {
  const dispatch = useAppDispatch();

  const { patientId } = useParams<'patientId'>();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState({
    activePage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
    sort: 'id',
    order: ASC,
  });

  const consultationList = useAppSelector(state => state.consultation.entities);
  const loading = useAppSelector(state => state.consultation.loading);
  const totalItems = useAppSelector(state => state.consultation.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        params: {page: paginationState.activePage - 1, size: paginationState.itemsPerPage, sort: `${paginationState.sort},${paginationState.order}`},
        patientId: patientId
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="consultation-heading" data-cy="ConsultationHeading">
        <Translate contentKey="dfaiApp.consultation.home.title">Consultations</Translate>
      </h2>
      <div className="table-responsive">
        {consultationList && consultationList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
              <th className="hand" onClick={sort('date')}>
                  <Translate contentKey="dfaiApp.consultation.date">Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('date')} />
                </th>
                <th className="hand" onClick={sort('practitionerSnapshot')}>
                  <Translate contentKey="dfaiApp.consultation.practitionerSnapshot">Practitioner Snapshot</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('practitionerSnapshot')} />
                </th>
              </tr>
            </thead>
            <tbody>
              {consultationList.map((consultation, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable" className="clickable-row">
                  <td onClick={() => navigate(`/patient/${patientId}/consultation/${consultation.id}`)}>{`${convertDateFromServer(consultation.date)} ${consultation.empty ? translate("dfaiApp.consultation.emptyLabel") : ""}`}</td>
                  <td onClick={() => navigate(`/patient/${patientId}/consultation/${consultation.id}`)}>{consultation.practitionerSnapshot}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="dfaiApp.consultation.home.notFound">No Consultations found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={consultationList && consultationList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Consultation;
