import { IPredictionFilter } from 'app/shared/model/prediction-filter.model';
import { IPatient } from 'app/shared/model/patient.model';
import { IProfessional } from './professional.model';

export interface IClinic {
  id?: string;
  name?: string;
  address?: string | null;
  postalCode?: string | null;
  city?: string | null;
  country?: string | null;
  phone?: string | null;
  email?: string | null;
  siret?: string | null;
  isActive?: boolean | null;
  licences?: number | null;
  predictionFilters?: IPredictionFilter[] | null;
  professionals?: IProfessional[] | null;
  patients?: IPatient[] | null;
  photoFileName?: string | null;
}

export const defaultValue: Readonly<IClinic> = {
  isActive: false,
};
