import React, { Ref, forwardRef } from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    type: string;
  }
  
  const ScalableTextarea = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>((props, ref) => {
    const { type, className, name, ...rest } = props;
  
    if (type === 'textarea') {
      return <textarea className={className} name={name} style={{whiteSpace:"pre-line"}} ref={ref as Ref<HTMLTextAreaElement>} {...rest} />;
    }
  
    return <input ref={ref as Ref<HTMLInputElement>} type={type} {...rest} />;
  });
  
  export default ScalableTextarea;