import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Form, Label, } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity, updateEntity, createEntity, reset, linkActPathology, unlinkActPathology } from './act.reducer';
import { getEntities as getPathologies } from '../pathology/pathology.reducer';

export const ActUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const actEntity = useAppSelector(state => state.act.entity);
  const loading = useAppSelector(state => state.act.loading);
  const updating = useAppSelector(state => state.act.updating);
  const updateSuccess = useAppSelector(state => state.act.updateSuccess);
  const pathologies = useAppSelector(state => state.pathology.entities);
  const [selectedPathology, setSelectedPathology] = useState(null);
  const [linkedPatho, setLinkedPatho] = useState(null);

  const handleSelectedPathologyChange = (event) => {
    setSelectedPathology(event.target.value);
  };

  
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(linkActPathology({actId: id, pathologyId: selectedPathology}));
  };

  const handleDeletePathologyLink = (event) => {
    event.preventDefault();
    dispatch(unlinkActPathology({actId: id, pathologyId: selectedPathology}));
  };

  const handleClose = () => {
    navigate('/act' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getPathologies({page:0, size:999, sort: "id"}));
  }, []);

  useEffect(() => {
    setLinkedPatho(actEntity?.pathologies && actEntity.pathologies.map(pathology => translate(`dfaiApp.pathology.${pathology.name}`)).sort((a, b) => a.localeCompare(b)).join(", "));
  }, [actEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    const entity = {
      ...actEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : actEntity

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dfaiApp.act.home.createOrEditLabel" data-cy="ActCreateUpdateHeading">
            <Translate contentKey="dfaiApp.act.home.createOrEditLabel">Create or edit a Act</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField name="id" required readOnly id="act-id" label={translate('dfaiApp.act.id')} validate={{ required: true }} />
              ) : null}
              <ValidatedField label={translate('dfaiApp.act.code')} id="act-code" name="code" data-cy="code" type="text" />
              <ValidatedField
                label={translate('dfaiApp.act.description')}
                id="act-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                label={translate('dfaiApp.act.toothMin')}
                id="act-toothMin"
                name="toothMin"
                data-cy="toothMin"
                type="number"
              />
              <ValidatedField
                label={translate('dfaiApp.act.toothMax')}
                id="act-toothMax"
                name="toothMax"
                data-cy="toothMax"
                type="number"
              />
              <ValidatedField
                label={translate('dfaiApp.act.sextantMin')}
                id="act-sextantMin"
                name="sextantMin"
                data-cy="sextantMin"
                type="number"
              />
              <ValidatedField
                label={translate('dfaiApp.act.allowAnyway')}
                id="act-allowAnyway"
                name="allowAnyway"
                data-cy="allowAnyway"
                type="checkbox"
              />
              <ValidatedField
                label={translate('dfaiApp.act.allowMissingTeeth')}
                id="act-missingTeeth"
                name="allowMissingTeeth"
                data-cy="allowMissingTeeth"
                type="checkbox"
              />
              <ValidatedField
                label={translate('dfaiApp.act.bridge')}
                id="act-bridge"
                name="bridge"
                data-cy="bridge"
                type="checkbox"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/act" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
        <Row>
          <Col md="8">
            <h3><Translate contentKey="dfaiApp.act.pathologies">Pathologies</Translate></h3>
              {linkedPatho && <div>{linkedPatho}</div>}
              {pathologies && <Form onSubmit={handleSubmit}>
              <Label htmlFor="selectOption">Choose an option:</Label>
              <select id="selectOption" value={selectedPathology} onChange={handleSelectedPathologyChange}>
                <option value=""></option>
                {[...pathologies].sort((a, b) => translate(`dfaiApp.pathology.${a.name}`).localeCompare(translate(`dfaiApp.pathology.${b.name}`))).map(pathology => 
                  <option value={pathology.id}>{translate(`dfaiApp.pathology.${pathology.name}`)}</option>
                )}
              </select>
              <Button className="m-1" type="submit">Add</Button>
              <Button className="m-1" onClick={handleDeletePathologyLink}>Remove</Button>
            </Form> }
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default ActUpdate;
