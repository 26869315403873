import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getClinicPathologies } from 'app/entities/pathology/pathology.reducer';
import { getEntity, updateEntity, createEntity, reset } from './prediction-filter.reducer';

export const PredictionFilterUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { clinicId, id } = useParams();
  const isNew = id === undefined;

  const pathologies = useAppSelector(state => state.pathology.entities);
  const predictionFilterEntity = useAppSelector(state => state.predictionFilter.entity);
  const loading = useAppSelector(state => state.predictionFilter.loading);
  const updating = useAppSelector(state => state.predictionFilter.updating);
  const updateSuccess = useAppSelector(state => state.predictionFilter.updateSuccess);

  const handleClose = () => {
    navigate(`/clinic/${clinicId}`);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getClinicPathologies(clinicId));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.treshold !== undefined && typeof values.treshold !== 'number') {
      values.treshold = Number(values.treshold);
    }

    const entity = {
      ...predictionFilterEntity,
      ...values,
      pathology: {id: values.pathology},
    };

    if (isNew) {
      dispatch(createEntity({entity, clinicId}));
    } else {
      dispatch(updateEntity({entity, clinicId}));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : predictionFilterEntity;

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dfaiApp.predictionFilter.home.createOrEditLabel" data-cy="PredictionFilterCreateUpdateHeading">
            <Translate contentKey="dfaiApp.predictionFilter.home.createOrEditLabel">Create or edit a PredictionFilter</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="prediction-filter-id"
                  label={translate('dfaiApp.predictionFilter.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('dfaiApp.predictionFilter.name')}
                id="prediction-filter-name"
                name="name"
                data-cy="name"
                type="select">
                  <option value="" key="0" />
                  <option value="panoramic" key="panoramic">panoramic</option>
                  <option value="xray" key="xray">xray</option>
                  <option value="photo" key="photo">photo</option>
              </ValidatedField>
              <ValidatedField
                label={translate('dfaiApp.predictionFilter.pathology')}
                id="prediction-filter-pathology"
                name="pathology"
                data-cy="pathology"
                type="select">
                  <option value="" key="0" />
                  { pathologies.map(pathology => (
                    <option value={pathology.id} key={pathology.id}>{translate(`dfaiApp.pathology.${pathology.name}`)}</option>
                  ))}
              </ValidatedField>
              <ValidatedField
                label={translate('dfaiApp.predictionFilter.treshold')}
                id="prediction-filter-treshold"
                name="treshold"
                data-cy="treshold"
                type="number"
                step="any"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/prediction-filter" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PredictionFilterUpdate;
