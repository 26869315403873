import React from 'react';

const CircleIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7" stroke="grey" strokeWidth="1" fill={color} />
    </svg>
  );
};

export default CircleIcon;