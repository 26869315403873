import patient from 'app/entities/patient/patient.reducer';
import professional from 'app/entities/professional/professional.reducer';
import clinic from 'app/entities/clinic/clinic.reducer';
import predictionFilter from 'app/entities/prediction-filter/prediction-filter.reducer';
import act from 'app/entities/act/act.reducer';
import scrapingConfig from 'app/entities/scraping-config/scraping-config.reducer';
import consultation from 'app/entities/patient/consultation/consultation.reducer';
import prediction from 'app/entities/patient/consultation/prediction.reducer';
import image from 'app/entities/patient/consultation/image/image.reducer';
import imageDiagnostic from 'app/entities/patient/consultation/image/image-diagnostic/image-diagnostic.reducer';
import pathology from 'app/entities/pathology/pathology.reducer';
import pathologyPrediction from 'app/entities/patient/consultation/image/pathology-prediction/pathology-prediction.reducer';
import quoteAnnotation from 'app/entities/quote-annotation/quote-annotation.reducer';
import quote from 'app/entities/patient/consultation/quotes/quote.reducer';
import matching from './patient/consultation/quotes/matching.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  patient,
  professional,
  clinic,
  predictionFilter,
  quote,
  act,
  scrapingConfig,
  consultation,
  image,
  imageDiagnostic,
  pathology,
  pathologyPrediction,
  quoteAnnotation,
  matching,
  prediction
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
