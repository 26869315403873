import { IAct } from 'app/shared/model/act.model';
import { IQuoteAnnotation } from 'app/shared/model/quote-annotation.model';
import { IUser } from 'app/shared/model/user.model';
import { IConsultation } from 'app/shared/model/consultation.model';

export interface IQuote {
  id?: string;
  sourceFileName?: string | null;
  originalName?: string | null;
  authorSnpashot?: string | null;
  acts?: IAct[] | null;
  annotations?: IQuoteAnnotation[] | null;
  author?: IUser | null;
  consultation?: IConsultation | null;
}

export const defaultValue: Readonly<IQuote> = {};
