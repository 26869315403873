import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'app/config/store';
import { getConsultationImage } from '../consultation.reducer';
import { useParams } from 'react-router';
import { getPatientPhoto } from '../../../patient/patient.reducer';

const ImageViewer = ({
  image,
  source = "consultation",
  imagePatientId = null,
  rounded = false,
  roundedCorner = false
}) => {

  const dispatch = useAppDispatch();
  const { patientId } = useParams();
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const fetchImage = async () => {
      if (image.storedName) {
        try {
          let response;
          if(source === "consultation") {
            response = await dispatch(getConsultationImage({ patientId, image: image })).unwrap();
          } else if(source === "patient") {
            response = await dispatch(getPatientPhoto(imagePatientId)).unwrap();
          }
          const objectURL = URL.createObjectURL(response);
          setImageUrl(objectURL);
          return () => URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error('Failed to load image:', error);
        }
      }
    };

    fetchImage();
  }, [dispatch, image, patientId]);


  return (
    <div className="h-100">
      {image && 
        <img
          id={`image-${image.id}`}
          src={imageUrl}
          draggable={false}
          className={`img-fluid h-100 ${rounded ? "rounded-circle" : roundedCorner ? "rounded" : ""}`}
          style={rounded ? { height: '5rem', width: '5rem', strokeWidth: '0.5px' } : {}}
        />
      }
    </div>
    )
};

export default ImageViewer;
