export enum ProfessionalStatus {
  HOLDER = 'HOLDER',

  LIBERAL_COLLABORATOR = 'LIBERAL_COLLABORATOR',

  MATCHING_USER = 'MATCHING_USER',

  SALARIED_COLLABORATOR = 'SALARIED_COLLABORATOR',

  ASSISTANT = 'ASSISTANT',

  HELPER_OR_SECRETARY = 'HELPER_OR_SECRETARY',

  SUBSTITUTE = 'SUBSTITUTE',

  STUDENT = 'STUDENT',
}
