import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import QuoteAnnotation from './quote-annotation';
import QuoteAnnotationDetail from './quote-annotation-detail';
import QuoteAnnotationUpdate from './quote-annotation-update';
import QuoteAnnotationDeleteDialog from './quote-annotation-delete-dialog';

const QuoteAnnotationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<QuoteAnnotation />} />
    <Route path="new" element={<QuoteAnnotationUpdate />} />
    <Route path=":id">
      <Route index element={<QuoteAnnotationDetail />} />
      <Route path="edit" element={<QuoteAnnotationUpdate />} />
      <Route path="delete" element={<QuoteAnnotationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default QuoteAnnotationRoutes;
