import React, { useEffect, useState } from 'react';
import './CustomCarousel.css';
import RawImageViewer from 'app/entities/patient/consultation/image/raw-image-viewer';
import { useNavigate, useParams } from 'react-router';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomCarousel = ({ items }) => {
  const { patientId, consultationId, id } = useParams();
  const navigate = useNavigate();
  const [sortedItems, setSortedItems] = useState(null);

  const sectionOrder = [
    "18", "17", "16", "15 14", "13 12", "11 21", "22 23", "24 25", "26", "27", "28",
    "48", "47", "46", "45 44", "43 42", "41 31", "32 33", "34 35", "36", "37", "38"
  ];

  useEffect(() => {
    setSortedItems([...items].sort(sortImages));
  },[items])

  const sortImages = (a, b) => {
    if (a.type === b.type) {
      if (a.type === "xray") {
        const sectionAIndex = sectionOrder.findIndex(order => order.includes(a.section));
        const sectionBIndex = sectionOrder.findIndex(order => order.includes(b.section));
        return sectionAIndex - sectionBIndex;
      }
      return 0; // If types are the same but not "xray", maintain order
    } else if (a.type === "panoramic") {
      return -1;
    } else if (b.type === "panoramic") {
      return 1;
    } else if (a.type === "xray") {
      return -1;
    } else if (b.type === "xray") {
      return 1;
    } else if (a.type === "photo") {
      return 1;
    } else {
      return -1;
    }
  };

  return (
        <div className="w-full  p-3 d-flex justify-content-start" style={{overflowX: "auto"}}>
            {sortedItems?.map((image) => (
                <div className="m-1 flex items-center justify-center"
                    onClick={() => navigate(`/patient/${patientId}/consultation/${consultationId}/image/${image.id}/edit`)}>
                    <div style={{height: "8vh", width: "max-content"}} className={`position-relative ${
                    image.id === id
                        ? 'border-green'
                        : 'rounded border border-4 border-white'
                    }`}>
                        <RawImageViewer image={image} roundedCorner/>
                        {image.validated && (
                            <div className="position-absolute top-0 start-0 w-100 text-green d-flex justify-content-end">
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    type="outline"
                                    height="3rem" fill="#22c55e"
                                    />
                            </div>
                        )}
                    </div>
                    
                    <div>
                        {image.type === 'xray' && (
                        <div className="d-flex justify-content-center">{image.section}</div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CustomCarousel;
