import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Act from './act';
import ActDetail from './act-detail';
import ActUpdate from './act-update';
import ActDeleteDialog from './act-delete-dialog';

const ActRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Act />} />
    <Route path="new" element={<ActUpdate />} />
    <Route path=":id">
      <Route index element={<ActDetail />} />
      <Route path="edit" element={<ActUpdate />} />
      <Route path="delete" element={<ActDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActRoutes;
