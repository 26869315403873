import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Professional from './professional';
import ProfessionalDetail from './professional-detail';
import ProfessionalUpdate from './professional-update';
import ProfessionalDeleteDialog from './professional-delete-dialog';

const ProfessionalRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Professional />} />
    <Route path="new" element={<ProfessionalUpdate />} />
    <Route path=":id">
      <Route index element={<ProfessionalDetail />} />
      <Route path="edit" element={<ProfessionalUpdate />} />
      <Route path="delete" element={<ProfessionalDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProfessionalRoutes;
