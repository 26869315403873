export const fillColor = (pathology, property) => {
    return(fillColorWithDefault(pathology, property, "white"));
  };

export const getColor = (pathology) => {
  return pathology?.name && colorPathologyMap.hasOwnProperty(pathology?.name) ? colorPathologyMap[pathology.name] : "white";

};

export const fillColorWithDefault = (pathology, property, defaultColor) => {
    const color = pathology?.name && colorPathologyMap.hasOwnProperty(pathology?.name) ? colorPathologyMap[pathology.name] : defaultColor;
    const style = {};
    style[property] = color;
    return style;
  };

export const fillColorWithDefaultFromName = (pathology: string, property, defaultColor) => {
    const color = pathology && colorPathologyMap.hasOwnProperty(pathology) ? colorPathologyMap[pathology] : defaultColor;
    const style = {};
    style[property] = color;
    return style;
  };
  
export const colorPathologyMap = {
    advancedDecay: "#FF0099",
    intermediateDecay: "#f72fa7",
    superficialDecay: "#fa5cbb",
    crown: "#87CEEB",
    inlayCore: "#999966",
    moderatePeriodontics: "#e3d005",
    normalPeriodontics: "#008a05",
    severePeriodontics: "#e31b05",
    screwPost: "#999966",
    tartar: "#FF7F50",
    abscess: "#800080",
    amalgam: "#33CC33",
    decay: "#FF0099",
    composite: "#33CC33",
    implant: "#66FFCC",
    periodontics: "#FFFFCC",
    metalCrown: "#87CEEB",
    abfraction: "#a42f13",
    abrasion: "#892710",
    unsuitableGingival: "#ff0601",
}
