import dayjs from 'dayjs';
import { IConsultation } from 'app/shared/model/consultation.model';
import { IUser } from 'app/shared/model/user.model';
import { IClinic } from 'app/shared/model/clinic.model';
import { Sex } from 'app/shared/model/enumerations/sex.model';

export interface IPatient {
  id?: string;
  firstName?: string | null;
  lastName?: string | null;
  birthdate?: dayjs.Dayjs | null;
  address?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  sex?: keyof typeof Sex | null;
  photoFileName?: string | null;
  socialNumber?: string | null;
  consultations?: IConsultation[] | null;
  users?: IUser[] | null;
  clinics?: IClinic[] | null;
  photo?: Blob | null;
  mutuelleName?: string | null;
  mutuelleNumber?: string | null;
  mutuelleMail?: string | null;
  missingTeeth?: string[];
  implants?: string[];
  crowns?: string[];
}

export const defaultValue: Readonly<IPatient> = {};
