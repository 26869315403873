import React, { useEffect, useState } from 'react';
import './dental-chart.scss';
import { translate } from 'react-jhipster';
import { isMobile } from 'react-device-detect';

const premolarImage = '/content/images/premolar.png';
const canineImage = '/content/images/canine-tooth.png';
const molarImage = '/content/images/molar.png';
const implantImage = '/content/images/implant.png';
const crownImage = '/content/images/crown.png';
const defaultToothImage = '/content/images/default-tooth.png';

interface DentalChartProps {
  upperTeeth: string[];
  lowerTeeth: string[];
  missingTeeth: string[];
  implants: string[];
  crowns: string[];
  setMissingTeeth: React.Dispatch<React.SetStateAction<string[]>>;
  setImplants: React.Dispatch<React.SetStateAction<string[]>>;
  setCrowns: React.Dispatch<React.SetStateAction<string[]>>;
}

const DentalChart: React.FC<DentalChartProps> = ({ upperTeeth, lowerTeeth, missingTeeth, implants, crowns, setMissingTeeth, setImplants, setCrowns }) => {
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [selectedTeeth, setSelectedTeeth] = useState<string[]>([]);
  const [sortedMissingTeeth, setSortedMissingTeeth] = useState(null);

  const toothOrder = [
    "18", "17", "16", "15", "14", "13", "12", "11",
    "21", "22", "23", "24", "25", "26", "27", "28",
    "38", "37", "36", "35", "34", "33", "32", "31",
    "41", "42", "43", "44", "45", "46", "47", "48"
  ];

  const handleToothClick = (tooth: string): void => {
    if (selectedStatus === 'missing') {
      setMissingTeeth(prev => {
        if (prev.includes(tooth)) {
          return prev.filter(t => t !== tooth);
        } else {
          return [...prev, tooth];
        }
      });
      setImplants(prev => prev.filter(t => t !== tooth));
      setCrowns(prev => prev.filter(t => t !== tooth));
    } else if (selectedStatus === 'implant') {
      setImplants(prev => {
        if (prev.includes(tooth)) {
          return prev.filter(t => t !== tooth);
        } else {
          return [...prev, tooth];
        }
      });
      setMissingTeeth(prev => prev.filter(t => t !== tooth));
      setCrowns(prev => prev.filter(t => t !== tooth));
    } else if (selectedStatus === 'crown') {
      setCrowns(prev => {
        if (prev.includes(tooth)) {
          return prev.filter(t => t !== tooth);
        } else {
          return [...prev, tooth];
        }
      });
      setMissingTeeth(prev => prev.filter(t => t !== tooth));
      setImplants(prev => prev.filter(t => t !== tooth));
    }
  };

  const handleMouseDown = (tooth: string) => {
    setIsSelecting(true);
    setSelectedTeeth([tooth]);
    handleToothClick(tooth);
  };

  const handleMouseOver = (tooth: string) => {
    if (isSelecting) {
      if (!selectedTeeth.includes(tooth)) {
        setSelectedTeeth(prev => [...prev, tooth]);
        handleToothClick(tooth);
      }
    }
  };

  const handleMouseUp = () => {
    setIsSelecting(false);
    setSelectedTeeth([]);
  };

  const resetTeeth = (): void => {
    setMissingTeeth([]);
    setImplants([]);
    setCrowns([]);
    setSelectedStatus(null);
  };

  const getImageForTooth = (tooth: string) => {
    if (implants.includes(tooth)) {
      return implantImage;
    } else if (crowns.includes(tooth)) {
      return crownImage;
    }
    const toothNum = parseInt(tooth);
    if ([14, 15, 24, 25, 34, 35, 44, 45].includes(toothNum)) {
      return premolarImage;
    } else if ([13, 12, 11, 21, 22, 23, 33, 32, 31, 41, 42, 43].includes(toothNum)) {
      return canineImage;
    } else if ([16, 17, 18, 26, 27, 28, 36, 37, 38, 46, 47, 48].includes(toothNum)) {
      return molarImage;
    }
    return defaultToothImage;
  };

  const isToothUpsideDown = (tooth: string) => {
    const toothNum = parseInt(tooth);
    return [14, 15, 24, 25, 13, 12, 11, 21, 22, 23].includes(toothNum);
  };

  useEffect(() => {
    if(missingTeeth) {
      setSortedMissingTeeth([...missingTeeth].sort((a, b) => toothOrder.indexOf(a) - toothOrder.indexOf(b)))
    }
  },[missingTeeth])


  return (
    <div className="d-flex flex-wrap dental-chart-container" onMouseUp={handleMouseUp}>
      <h2 style={{ fontWeight: 'bold', marginTop: '2rem' }}>{translate("dfaiApp.patient.dentalChart")}</h2> {/* Titre en gras avec margin-top */}
      <div className={`actions ${isMobile ? "mobile" : ""}`}>
        <button onClick={() => setSelectedStatus('missing')} className={selectedStatus === 'missing' ? 'active' : ''}>{translate("dfaiApp.patient.missingTooth")}</button>
        <button onClick={() => setSelectedStatus('implant')} className={selectedStatus === 'implant' ? 'active' : ''}>{translate("dfaiApp.patient.implant")}</button>
        <button onClick={() => setSelectedStatus('crown')} className={selectedStatus === 'crown' ? 'active' : ''}>{translate("dfaiApp.patient.crown")}</button>
        <button onClick={resetTeeth} className="reset-button">{translate("dfaiApp.patient.reset")}</button>
      </div>
      {missingTeeth && crowns && implants && <div className="dental-chart">
        <div className="teeth-row upper-teeth">
          {upperTeeth.map(tooth => (
            <div 
              key={tooth} 
              className={`tooth ${missingTeeth.includes(tooth) ? 'missing' : ''} ${implants.includes(tooth) ? 'implant' : ''} ${crowns.includes(tooth) ? 'crown' : ''}`}
              onMouseDown={() => handleMouseDown(tooth)}
              onMouseOver={() => handleMouseOver(tooth)}
            >
              <div className="tooth-image">
                {!(missingTeeth.includes(tooth) && !implants.includes(tooth) && !crowns.includes(tooth)) && (
                  <img 
                    src={getImageForTooth(tooth)} 
                    alt={`Tooth ${tooth}`} 
                    className={isToothUpsideDown(tooth) ? 'upside-down' : ''} 
                  />
                )}
              </div>
              <span className="tooth-number">{tooth}</span>
            </div>
          ))}
        </div>
        <div className="teeth-row lower-teeth">
          {lowerTeeth.map(tooth => (
            <div 
              key={tooth} 
              className={`tooth ${missingTeeth.includes(tooth) ? 'missing' : ''} ${implants.includes(tooth) ? 'implant' : ''} ${crowns.includes(tooth) ? 'crown' : ''}`}
              onMouseDown={() => handleMouseDown(tooth)}
              onMouseOver={() => handleMouseOver(tooth)}
            >
              <div className="tooth-image">
                {!(missingTeeth.includes(tooth) && !implants.includes(tooth) && !crowns.includes(tooth)) && (
                  <img 
                    src={getImageForTooth(tooth)} 
                    alt={`Tooth ${tooth}`} 
                    className={isToothUpsideDown(tooth) ? 'upside-down' : ''} 
                  />
                )}
              </div>
              <span className="tooth-number">{tooth}</span>
            </div>
          ))}
        </div>
      </div>}
      {sortedMissingTeeth && <div className="missing-teeth">
        <h3 className="pathology-title" style={{ fontSize: '1rem' }}>Dents manquantes : {sortedMissingTeeth.join(', ')}</h3>
        {/*
        <h3 className="pathology-title" style={{ fontSize: '1rem' }}>Dents manquantes secteur 01 : {upperMissingTeeth.length}</h3>
        <h3 className="pathology-title" style={{ fontSize: '1rem' }}>Dents manquantes secteur 02 : {lowerMissingTeeth.length}</h3>
        <h3 className="pathology-title" style={{ fontSize: '1rem' }}>Dents manquantes secteur 00 : {missingTeeth.length}</h3>
        */}
      </div>}
    </div>
  );
};

export default DentalChart;
