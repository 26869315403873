import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ScrapingConfig from './scraping-config';
import ScrapingConfigDetail from './scraping-config-detail';
import ScrapingConfigUpdate from './scraping-config-update';
import ScrapingConfigDeleteDialog from './scraping-config-delete-dialog';

const ScrapingConfigRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ScrapingConfig />} />
    <Route path="new" element={<ScrapingConfigUpdate />} />
    <Route path=":id">
      <Route index element={<ScrapingConfigDetail />} />
      <Route path="edit" element={<ScrapingConfigUpdate />} />
      <Route path="delete" element={<ScrapingConfigDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ScrapingConfigRoutes;
