import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Pathology from './pathology';
import PathologyDetail from './pathology-detail';
import PathologyUpdate from './pathology-update';
import PathologyDeleteDialog from './pathology-delete-dialog';

const PathologyRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Pathology />} />
    <Route path="new" element={<PathologyUpdate />} />
    <Route path=":id">
      <Route index element={<PathologyDetail />} />
      <Route path="edit" element={<PathologyUpdate />} />
      <Route path="delete" element={<PathologyDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PathologyRoutes;
