import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Consultation from './consultation';
import Image from './image';
import ConsultationDetail from './consultation-detail';
import ConsultationUpdate from './consultation-update';
import ConsultationDeleteDialog from './consultation-delete-dialog';
import PatientReport from './patient-report';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

const ConsultationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Consultation />} />
    <Route path="new" element={
      <PrivateRoute hasAnyAuthorities={[AUTHORITIES.CREATE_CONSULTATION]}>
        <ConsultationUpdate />
      </PrivateRoute>
    } />
    <Route path="report" element={<PatientReport />} />
    <Route path=":consultationId">
      <Route index element={<ConsultationDetail />} />
      <Route path="edit" element={
        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.CREATE_CONSULTATION]}>
          <ConsultationUpdate />
        </PrivateRoute>} />
      <Route path="delete" element={
        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.UPDATE_CONSULTATION]}>
          <ConsultationDeleteDialog />
        </PrivateRoute>} />
      <Route path="image/*" element={<Image />} />
      <Route path="report" element={<PatientReport />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ConsultationRoutes;
