import { IPathology } from './pathology.model';

export interface IPredictionFilter {
  id?: string;
  name?: string | null;
  treshold?: number | null;
  pathology?: IPathology | null;
}

export const defaultValue: Readonly<IPredictionFilter> = {};
