import {
  faCogs,
  faBan,
  faAsterisk,
  faArrowLeft,
  faBell,
  faBook,
  faCloud,
  faDatabase,
  faEye,
  faFlag,
  faHeart,
  faHome,
  faList,
  faLock,
  faPencilAlt,
  faPlus,
  faSave,
  faSearch,
  faSort,
  faSync,
  faRoad,
  faSignInAlt,
  faSignOutAlt,
  faTachometerAlt,
  faTasks,
  faThList,
  faTimesCircle,
  faTrash,
  faUser,
  faUserPlus,
  faUsers,
  faUsersCog,
  faWrench,
  faCamera,
  faCheckCircle,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faPlusCircle,
  faMinusCircle,
  faSun,
  faSquarePlus,
  faArrows,
  faArrowRightArrowLeft,
  faCircleCheck,
  faArchive
} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';

export const loadIcons = () => {
  library.add(
    faArrowLeft,
    faAsterisk,
    faBan,
    faBell,
    faBook,
    faCloud,
    faCogs,
    faDatabase,
    faEye,
    faFlag,
    faHeart,
    faHome,
    faList,
    faLock,
    faPencilAlt,
    faPlus,
    faRoad,
    faSave,
    faSignInAlt,
    faSignOutAlt,
    faSearch,
    faSort,
    faSync,
    faTachometerAlt,
    faTasks,
    faThList,
    faTimesCircle,
    faTrash,
    faUser,
    faUserPlus,
    faUsers,
    faUsersCog,
    faWrench,
    faCamera,
    faCheckCircle,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faPlusCircle,
    faMinusCircle,
    faSun,
    faArrowRightArrowLeft,
    faSquarePlus,
    faArrows,
    faCircleCheck,
    faArchive
  );
};
