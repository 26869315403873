import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './professional.reducer';

export const ProfessionalDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const professionalEntity = useAppSelector(state => state.professional.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="professionalDetailsHeading">
          <Translate contentKey="dfaiApp.professional.detail.title">Professional</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="dfaiApp.professional.id">Id</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.id}</dd>
          <dt>
            <span id="firstName">
              <Translate contentKey="dfaiApp.professional.firstName">First Name</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.firstName}</dd>
          <dt>
            <span id="lastName">
              <Translate contentKey="dfaiApp.professional.lastName">Last Name</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.lastName}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="dfaiApp.professional.status">Status</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.status && professionalEntity.status.map( status => { return translate(`dfaiApp.ProfessionalStatus.${status}`);}).join(", ")}</dd>
          <dt>
            <span id="corporateName">
              <Translate contentKey="dfaiApp.professional.corporateName">Corporate Name</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.corporateName}</dd>
          <dt>
            <span id="address">
              <Translate contentKey="dfaiApp.professional.address">Address</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.address}</dd>
          <dt>
            <span id="zipCode">
              <Translate contentKey="dfaiApp.professional.zipCode">Zip Code</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.zipCode}</dd>
          <dt>
            <span id="city">
              <Translate contentKey="dfaiApp.professional.city">City</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.city}</dd>
          <dt>
            <span id="country">
              <Translate contentKey="dfaiApp.professional.country">Country</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.country}</dd>
          <dt>
            <span id="phoneNumber">
              <Translate contentKey="dfaiApp.professional.phoneNumber">Phone Number</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.phoneNumber}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="dfaiApp.professional.email">Email</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.email}</dd>
          <dt>
            <span id="website">
              <Translate contentKey="dfaiApp.professional.website">Website</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.website}</dd>
          <dt>
            <span id="rpps">
              <Translate contentKey="dfaiApp.professional.rpps">Rpps</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.rpps}</dd>
          <dt>
            <span id="siret">
              <Translate contentKey="dfaiApp.professional.siret">Siret</Translate>
            </span>
          </dt>
          <dd>{professionalEntity.siret}</dd>
          <dt>
            <Translate contentKey="dfaiApp.professional.user">User</Translate>
          </dt>
          <dd>{professionalEntity.user ? professionalEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/professional" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/professional/${professionalEntity.id}/edit`} replace color="info">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProfessionalDetail;
