import { IImage } from 'app/shared/model/image.model';

export interface IImageDiagnostic {
  id?: string;
  raw?: string | null;
  model?: string | null;
  modelVersionId?: string | null;
  deployedModelId?: string | null;
  modelDisplayName?: string | null;
  image?: IImage | null;
}

export const defaultValue: Readonly<IImageDiagnostic> = {};
