import { TranslatorContext, Storage } from 'react-jhipster';

import { setLocale } from 'app/shared/reducers/locale';

TranslatorContext.setDefaultLocale('fr');
TranslatorContext.setRenderInnerTextForMissingKeys(false);

export const languages: any = {
  fr: { name: 'Français' },
  en: { name: 'English' },
  'pt-pt': { name: 'Português' },
  'pt-BR': { name: 'Português (BR)' },
  it: {name: 'Italiano'},
  es: {name: 'Español'}
  // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
};

export const locales = Object.keys(languages).sort();

export const registerLocale = store => {
  store.dispatch(setLocale(Storage.session.get('locale', 'fr')));
};
