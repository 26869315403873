import { IPathology } from 'app/shared/model/pathology.model';
import { IImage } from './image.model';

export interface IPathologyPrediction {
  id?: string;
  position?: string | Object | null;
  annotation?: string | null;
  toothCount?: number | null;
  teeth?: string | null;
  sextant?: string | null;
  confidence?: number[] | null;
  pathology?: IPathology | null;
  image?: IImage | null;
  mmDistance?: number | null;
  practitionerSnapshot?: string | null;
  rpps?: string | null;
}

export const defaultValue: Readonly<IPathologyPrediction> = {};
