import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, getPaginationState, JhiPagination, JhiItemCount, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from '../patient/consultation/quotes/matching.reducer';
import { convertDateFromServer } from 'app/shared/util/date-utils';
import { IMatching } from 'app/shared/model/matching.model';
import { isMobile } from 'react-device-detect';

export const Matching = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const toothOrder = [
    "00","01","02","03","04","05","06","07","08","18", "17", "16", "15", "14", "13", "12", "11",
    "21", "22", "23", "24", "25", "26", "27", "28",
    "38", "37", "36", "35", "34", "33", "32", "31",
    "41", "42", "43", "44", "45", "46", "47", "48"
  ];

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const matchingList: IMatching[] = useAppSelector(state => state.matching.entities);
  const loading = useAppSelector(state => state.matching.loading);
  const totalItems = useAppSelector(state => state.matching.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: 10,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const getPractitionerName = (matchingEntry) => {
    if (!matchingEntry.practitioner) {
      return matchingEntry.consultationPractitioner;
    }
    const filteredPractitioners = Object.entries(matchingEntry.practitioner)
      .filter(([quoteId, practitioner]) => practitioner && practitioner !== '')
      .map(([quoteId, practitioner]) => practitioner);
    return filteredPractitioners.length > 0 ? filteredPractitioners[0]+"" : matchingEntry.consultationPractitioner;
  }

  return (
    <div>
      <div className="table-responsive">
        {matchingList && matchingList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  {isMobile ?
                    <Translate contentKey="dfaiApp.patient.name">Patient name</Translate>
                    :
                    <Translate contentKey="dfaiApp.quote.patient">Patient name</Translate>
                  }
                </th>
                {!isMobile && <th>
                  <Translate contentKey="dfaiApp.quote.dentist">Surgeon-dentist</Translate>{' '}
                </th>}
                <th>
                  <Translate contentKey="dfaiApp.quote.annotations">Annotations</Translate>{' '}
                </th>
                <th>
                  <Row>
                    {!isMobile && <Col md="6">
                      <Translate contentKey="dfaiApp.quote.detail.title">Quote</Translate>{' '}
                    </Col>}
                    <Col md="6">
                      <Translate contentKey="dfaiApp.quote.matching">Matching</Translate>{' '}
                    </Col>
                  </Row>
                </th>
              </tr>
            </thead>
            <tbody>
              {(!matchingList || matchingList.length === 0) && <Translate contentKey="dfaiApp.quote.noQuote">No quote</Translate>}
              {matchingList?.map((entry, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td className="col-md-1">
                    <a href={`/patient/${entry.patient.id}/consultation/${entry.consultationId}`}>
                      {entry.patient.firstName} {entry.patient.lastName}
                    </a>
                    <p>({convertDateFromServer(entry.patient.birthdate)})</p>
                  </td>
                  {!isMobile && <td className="col-md-1">{getPractitionerName(entry)}</td>}
                  <td className={isMobile ? "col-md-6" : "col-md-4"}>
                    <div className="d-flex flex-wrap" style={{width: "auto"}}>
                      {Object.entries(entry.teethPerPathology)
                        .sort(([a, a1], [b, b1]) => translate(`dfaiApp.pathology.${a}`).localeCompare(translate(`dfaiApp.pathology.${b}`)))
                        .map(([pathology, teeth]) => (
                          <div className="d-flex flex-wrap">
                            {translate(`dfaiApp.pathology.${pathology}`)}(
                              {[...teeth].sort((a, b) => toothOrder.indexOf(a) - toothOrder.indexOf(b)).map((tooth, index) => (
                                <span>{index !== 0 && tooth !== null && tooth !== "" ? `,${tooth}` : tooth}</span>
                              ))}
                            )&nbsp;
                          </div>
                          ))
                        }
                    </div>
                  </td>
                  <td>
                    {Object.entries(entry.practitioner).map(([quoteId, practitioner], index) => (
                      <Row key={quoteId}>
                        {!isMobile && <Col md="6">
                          <div className="pb-1">
                            <a href={`api/patients/${entry.patient.id}/consultations/${entry.consultationId}/quotes/${quoteId}/file`} target="_blank" >
                              {entry.quoteDate[quoteId] && <p>
                                <Translate contentKey="dfaiApp.quote.datePrefix" /> {entry.quoteDate[quoteId]} 
                              </p>}
                            </a>
                            {entry.treatment[quoteId] && <p>
                              <Translate contentKey="dfaiApp.quote.treatment" /> :  {entry.treatment[quoteId]}
                            </p>}
                          </div> 
                        </Col>}
                        <Col md={isMobile ? "12" : "6"}>
                          {entry.matchedQuoteActs[quoteId]?.length > 0 &&
                              <div>
                                {entry.unmatchedQuoteActs[quoteId].length === 0 ?
                                  <div className="badge bg-success m-1">Matching ok</div>
                                  :
                                  <div className={isMobile ? "d-flex flex-column" : ""}>
                                    <div className="d-flex inline-flex">Matching ok :</div>
                                    {entry.matchedQuoteActs[quoteId].map(act => (
                                      <div key={`${quoteId}-matched-${act.id}`} className="badge bg-success m-1 fit-content">{act}</div>
                                    ))}
                                  </div>}
                              </div>
                          }
                          {entry.unmatchedQuoteActs[quoteId]?.length > 0 &&
                            <div className={isMobile ? "d-flex flex-column" : ""}>
                              <div className="d-flex inline-flex">Unmatched acts :</div>
                              {entry.unmatchedQuoteActs[quoteId].map(act => (
                                <div key={`${quoteId}-unmatched-${act.id}`} className="badge bg-danger m-1 fit-content" >{act}</div>
                              ))}
                            </div>
                          }
                        </Col>
                        {Object.entries.length === index - 1 && <hr/>}
                      </Row>
                    ))}
                    {Object.entries(entry.practitioner).length === 0 && 
                      <Row>
                        {!isMobile && <Col md="6"/>}
                        <Col md="6">
                        <div className="badge bg-warning m-1"><Translate contentKey="dfaiApp.quote.missing" /></div>
                        </Col>
                      </Row>}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="dfaiApp.quote.home.notFound">No Quotes found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={matchingList && matchingList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Matching;
