import axios from 'axios';
import { createAsyncThunk, isPending } from '@reduxjs/toolkit';
import { createEntitySlice, serializeAxiosError, EntityState } from 'app/shared/reducers/reducer.utils';

const initialState: EntityState<string> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: "",
  overview: "",
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

const apiUrl = (patientId: string) => `api/patients/${patientId}/consultations`;

export const predict = createAsyncThunk(
  'prediction/predict',
  async ({patientId, consultationId}: {patientId: string; consultationId: string}) => {
    const requestUrl = `api/patients/${patientId}/consultations/${consultationId}/predict`;
    const response = await axios.get<string>(requestUrl);
    return response.data; // return the actual data here
  },
  { serializeError: serializeAxiosError },
);

// slice

export const PredictionSlice = createEntitySlice({
  name: 'prediction',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(predict.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload; // directly use the payload since it's already data
      })
      .addCase(predict.rejected, (state, action) => {
        state.loading = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message || 'Something went wrong';
      })
      .addMatcher(isPending(predict), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
  },
});

export const { reset } = PredictionSlice.actions;

// Reducer
export default PredictionSlice.reducer;
