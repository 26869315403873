import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Form, Label } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity, linkActPathology, unlinkActPathology } from './act.reducer';
import { getEntities as getPathologies } from '../pathology/pathology.reducer';

export const ActDetail = () => {
  const dispatch = useAppDispatch();
  const [linkedPatho, setLinkedPatho] = useState(null);

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const actEntity = useAppSelector(state => state.act.entity);

  useEffect(() => {
    setLinkedPatho(actEntity?.pathologies && actEntity.pathologies
      .map(pathology => translate(`dfaiApp.pathology.${pathology.name}`))
      .sort((a, b) => a.localeCompare(b)).join(", "));
  }, [actEntity]);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="actDetailsHeading">
          <Translate contentKey="dfaiApp.act.detail.title">Act</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="dfaiApp.act.id">Id</Translate>
            </span>
          </dt>
          <dd>{actEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="dfaiApp.act.code">Code</Translate>
            </span>
          </dt>
          <dd>{actEntity.code}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="dfaiApp.act.description">Description</Translate>
            </span>
          </dt>
          <dd>{actEntity.description}</dd>
          <dt>
            <span id="toothMin">
              <Translate contentKey="dfaiApp.act.toothMin">Tooth min</Translate>
            </span>
          </dt>
          <dd>{actEntity.toothMin}</dd>
          <dt>
            <span id="toothMax">
              <Translate contentKey="dfaiApp.act.toothMax">Tooth max</Translate>
            </span>
          </dt>
          <dd>{actEntity.sextantMax}</dd>
          <dt>
            <span id="sextantMin">
              <Translate contentKey="dfaiApp.act.sextantMin">sextant min</Translate>
            </span>
          </dt>
          <dd>{actEntity.sextantMin}</dd>
          <dt>
            <span id="allowAnyway">
              <Translate contentKey="dfaiApp.act.allowAnyway">allow anyway</Translate>
            </span>
          </dt>
          <dd>{actEntity.allowAnyway ? "Oui" : "Non"}</dd>
          <dt>
            <span id="allowMissingTeeth">
              <Translate contentKey="dfaiApp.act.allowMissingTeeth">Allow missing teeth</Translate>
            </span>
          </dt>
          <dd>{actEntity.allowMissingTeeth ? "Oui" : "Non"}</dd>
          <dt>
            <span id="bridge">
              <Translate contentKey="dfaiApp.act.bridge">Is bridge</Translate>
            </span>
          </dt>
          <dd>{actEntity.bridge ? "Oui" : "Non"}</dd>
          <dt>
            <span id="pathologies">
              <Translate contentKey="dfaiApp.act.pathologies">Pathologies</Translate>
            </span>
          </dt>
          {linkedPatho && <dd>{linkedPatho}</dd>}
        </dl>
        <Button tag={Link} to="/act" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/act/${actEntity.id}/edit`} replace color="info">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ActDetail;
