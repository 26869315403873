import { IQuote } from 'app/shared/model/quote.model';
import { IImage } from 'app/shared/model/image.model';
import { IUser } from 'app/shared/model/user.model';
import { IPatient } from 'app/shared/model/patient.model';
import dayjs from 'dayjs';
import { IReport } from './report.model';

export interface IConsultation {
  id?: string;
  date?: dayjs.Dayjs | null;
  practitionerSnapshot?: string | null;
  quotes?: IQuote[] | null;
  images?: IImage[] | null;
  practitioner?: IUser | null;
  patient?: IPatient | null;
  report?: IReport | null;
  empty?: boolean | null;
  mail?: string | null;
}

export const defaultValue: Readonly<IConsultation> = {};
