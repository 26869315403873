// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Carousel */
.custom-carousel-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: auto;
  overflow: hidden;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.carousel-item img {
  max-height: 100%;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 15px;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -50px;
}

.carousel-indicators li {
  background-color: #000;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.carousel-caption {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  color: #fff;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/component/CustomCarousel.css"],"names":[],"mappings":"AAAA,aAAA;AAEA;EACI,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;AAAJ;;AAGE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAAJ;;AAGE;EACE,gBAAA;EACA,eAAA;EACA,sBAAA;KAAA,mBAAA;EACA,mBAAA;AAAJ;;AAGE;;EAEE,SAAA;AAAJ;;AAGE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAAJ;;AAGE;EACE,sBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAAJ;;AAGE;EACE,kBAAA;EACA,YAAA;EACA,UAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,8BAAA;EACA,YAAA;EACA,kBAAA;AAAJ","sourcesContent":["/* Carousel */\n\n.custom-carousel-container {\n    position: relative;\n    width: 100%;\n    max-width: 800px;\n    margin: auto;\n    overflow: hidden;\n  }\n  \n  .carousel-item {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 400px;\n  }\n  \n  .carousel-item img {\n    max-height: 100%;\n    max-width: 100%;\n    object-fit: contain;\n    border-radius: 15px;\n  }\n  \n  .carousel-control-prev,\n  .carousel-control-next {\n    width: 5%;\n  }\n  \n  .carousel-indicators {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    bottom: -50px;\n  }\n  \n  .carousel-indicators li {\n    background-color: #000;\n    width: 12px;\n    height: 12px;\n    border-radius: 50%;\n  }\n  \n  .carousel-caption {\n    position: absolute;\n    bottom: 10px;\n    left: 10px;\n    right: 10px;\n    color: #fff;\n    text-align: center;\n    background: rgba(0, 0, 0, 0.5);\n    padding: 5px;\n    border-radius: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
