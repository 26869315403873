import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getConsultationImage } from '../consultation.reducer';
import { deleteEntity as deletePathologyPrediction } from 'app/entities/patient/consultation/image/pathology-prediction/pathology-prediction.reducer';
import { useParams } from 'react-router';
import { Translate, translate } from 'react-jhipster';
import { colorPathologyMap, fillColor, fillColorWithDefault, fillColorWithDefaultFromName, getColor } from '../consultation-utils';
import { groupBy } from 'lodash';
import CircleIcon from 'app/shared/component/circle-icon';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { isMobileOnly } from 'react-device-detect';

const ImageViewer = ({
  imageEntity,
  patientId,
  pathologyPredictions,
  brightness = 100,
  contrast = 100,
  zoomModifier = 0,
  svgRef = null,
  handleClick = (e, currentZoom, pin) => {},
  handlePathologyPredictionSelected = (prediction, currentZoom) => {},
  onPathologyPredictionDragPinClick = (event, pin, currentZoom) => {},
  onPathologyPredictionDragBoxClick = (event, currentZoom) => {},
  handleMouseMove = (e) => {},
  zoomPredictionRequest = null,
  showLabels = false,
  startCoords = null,
  endCoords = null,
  rulerSelected = false,
  bboxSelected = false,
  labelBoxSelected = false,
  tempPredictionToRender = null,
  scaleOn = null,
  onEditClick= (patho) => {},
  handleTouchMove = (event) => {},
  containerRef,
  imageEditable = false,
  draggable = false,
}) => {
  const loggedProfessional = useAppSelector(state => state.authentication.account.loggedProfessional);
  const consultationEntity = useAppSelector(state => state.consultation.entity);
  const { id, consultationId } = useParams();
  const dispatch = useAppDispatch();
  const [imageUrl, setImageUrl] = useState('');
  const [selectedPathologyPrediction, setSelectedPathologyPrediction] = useState(tempPredictionToRender);
  const [selectedPathologyPredictionDeleteConfirmation, setSelectedPathologyPredictionDeleteConfirmation] = useState(false);
  const [pathologyPredictionsWithPosition, setPathologyPredictionsWithPositions] = useState(null);
  const zoom = useRef(1);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartX = useRef(0);
  const dragStartY = useRef(0);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [editLabelPosition, setEditLabelPosition] = useState({top: 0, left: 0});
  const imageRef = useRef(null);
  const imageWidth = useRef(imageEntity.width);
  const imageHeight = useRef(imageEntity.height);
  const hideContainerWidth = useRef(null);
  const hideContainerHeight = useRef(null);
  const [forceRender, setForceRender] = useState(null);

  const handleUpdateSelectedPatho = (event, prediction) => {
    if (imageEditable) {
      event.preventDefault();
      event.stopPropagation();
      const left = event.clientX - containerRef.current.getBoundingClientRect().left + 5;
      const top = event.clientY - containerRef.current.getBoundingClientRect().top + 5;
      setEditLabelPosition({left: left, top: top});
      setSelectedPathologyPrediction(prediction);
      handlePathologyPredictionSelected(prediction, zoom.current);
    }
  }

  const canEdit = () => {
    return consultationEntity.practitioner.id === loggedProfessional.id;
  }

  useEffect(() => {
    if(zoomPredictionRequest) {
      handlePathologyPredictionSelected(zoomPredictionRequest, zoom.current);
    }
  },[zoomPredictionRequest])

  const handleDelete = () => {
    if(canEdit()) {
      dispatch(deletePathologyPrediction({patientId, consultationId, imageId: imageEntity.id, id: selectedPathologyPrediction.id}));
      setSelectedPathologyPrediction(null);
      setSelectedPathologyPredictionDeleteConfirmation(false);
    }
  }

  useEffect(() => {
    setSelectedPathologyPrediction(tempPredictionToRender);
    setEditLabelPosition({top: 0, left: 0});
  }, [tempPredictionToRender])


  //useEffect(() => {
  //  setImageUrl(null);
  //  window.addEventListener('resize', handleScreenResize);
  //  return () => {
  //    window.removeEventListener('resize', handleScreenResize);
  //  };
  //}, []);

  useEffect(() => {
    if(imageEntity?.width && imageEntity.height) {
      hideContainerWidth.current = null;
      hideContainerHeight.current = null;
      imageWidth.current = imageEntity.width;
      imageHeight.current = imageEntity.height;
    }
    const fetchImage = async () => {
      if (imageEntity?.storedName) {
        setForceRender(null);
        try {
          setTranslateX(0);
          zoom.current = 1;
          setImageUrl(null);
          const response = await dispatch(getConsultationImage({ patientId, image: imageEntity })).unwrap();
          const objectURL = URL.createObjectURL(response);
          setImageUrl(objectURL);
          return () => URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error('Failed to load image:', error);
        }
      }
    };
    fetchImage();
    setTimeout(() => setForceRender(true), 200);
  }, [dispatch, imageEntity, patientId]);

  useEffect(() => {
    if (containerRef?.current && imageEntity && imageUrl) {
      updateZoom();
    }
  }, [imageUrl, containerRef])

  useEffect(() => {
    if (pathologyPredictions){
      const sortedList = pathologyPredictions
        .map(p => {return {...p, position: JSON.parse(p.position)}})
        .sort((a,b) => {a.position.y1 < b.position.y1 ? 1 : -1});
      setPathologyPredictionsWithPositions(sortedList);
    }
  }, [pathologyPredictions])

  useEffect(() => {
    if(!selectedPathologyPrediction) {
      setEditLabelPosition({top: 0, left: 0})
    }
  }, [selectedPathologyPrediction])

  const handleScreenResize = () => {
    setTranslateX(0);
    setTranslateY(0);
    hideContainerWidth.current = null;
    hideContainerHeight.current = null;
    updateZoom()
  }

  const updateZoom = () => {
    if ( containerRef.current && imageEntity.width && imageEntity.height) {
      setForceRender(null);
      let containerWidth = containerRef.current.getBoundingClientRect().width;
      if (showLabels) {
        containerWidth = (8*containerWidth)/12
      }
      let containerHeight = containerRef.current.getBoundingClientRect().height;
      if(isMobileOnly && showLabels) {
        containerHeight = containerHeight*0.6;
      }
      const widthZoom = imageWidth.current > containerWidth ? containerWidth / imageWidth.current : 1;
      const heightZoom = imageHeight.current > containerHeight ? containerHeight / imageHeight.current : 1;
      let containerZoom;
      if (scaleOn ==='width') {
        containerZoom = widthZoom;
      } else if(scaleOn === 'height') {
        containerZoom = heightZoom;
      } else {
        containerZoom = widthZoom < heightZoom ? widthZoom : heightZoom;
      }
        zoom.current = containerZoom+zoomModifier;
        if (hideContainerWidth.current === null) {
          hideContainerWidth.current = getInitialImageWidth(zoom.current);
        }
        if (hideContainerHeight.current === null) {
          hideContainerHeight.current = getInitialImageHeight(zoom.current);
        }
        setTimeout(() => setForceRender(true), 100);
    }
  }  

  const getImageWidth = (currentZoom) => {
    return imageEntity.width ? `${imageEntity.width * currentZoom}px` : '1000px';
  }
  const getImageHeight = (currentZoom) => {
    return imageEntity.height ? `${imageEntity.height * currentZoom}px` : '1000px';
  }

  const getInitialImageWidth = (currentZoom) => {
    let containerWidth = containerRef.current.getBoundingClientRect().width;
    if (showLabels) {
      containerWidth = (8*containerWidth)/12
    }
    const zoomWidth = imageEntity.width * currentZoom;
    const width = containerWidth < zoomWidth ? containerWidth : zoomWidth;
    return `${width}px`;
  }

  const getInitialImageHeight = (currentZoom) => {
    const containerHeight = containerRef.current.getBoundingClientRect().height;
    const zoomHeight = imageEntity.height * currentZoom;
    const height = containerHeight < zoomHeight ? containerHeight : zoomHeight;
    return `${height}px`;
  }
  
  useEffect(() => {
    setTranslateX(0);
    setTranslateY(0);
    hideContainerWidth.current = null;
    hideContainerHeight.current = null;
    updateZoom()
  }, [showLabels])

  useEffect(() => {
    updateZoom()
  }, [zoomModifier])

  const handleDragStart = (event) => {
    if (draggable) {
      setIsDragging(true);
      dragStartX.current = event.clientX - translateX;
      dragStartY.current = event.clientY - translateY;
      event.preventDefault();
    }
  };

  const handleDrag = (event) => {
    if (isDragging) {
      const newTranslateX = event.clientX - dragStartX.current;
      const newTranslateY = event.clientY - dragStartY.current;
      setTranslateX(newTranslateX);
      setTranslateY(newTranslateY);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  {/* PIN MANAGEMENT */}
  const [draggingPin, setDraggingPin] = useState(null);

  const handlePinMouseDown = (event, pinPosition) => {
    event.stopPropagation();
    setDraggingPin(pinPosition);
  };
  
  const handlePinMouseMove = (event, currentZoom) => {
    if (!draggingPin) return;
    let dx = null;
    let dy = null;
    let newPosition = { ...selectedPathologyPrediction.position };
  
    switch (draggingPin) {
      case 'topLeft':
        if (newPosition.x1 > newPosition.x2) {
          dx = (event.clientX - newPosition.x2)// / currentZoom;
          newPosition.x2 += dx;
        } else {
          dx = (event.clientX - newPosition.x1)// / currentZoom;
          newPosition.x1 += dx;
        }
        if (newPosition.y1 > newPosition.y2) {
          dy = (event.clientY - newPosition.y2)// / currentZoom;
          newPosition.y2 += dy;
        } else {
          dy = (event.clientY - newPosition.y1)// / currentZoom;
          newPosition.y1 += dy;
        }
        break;
      case 'topRight':
        if (newPosition.x2 > newPosition.x1) {
          dx = (event.clientX - newPosition.x2)// / currentZoom;
          newPosition.x2 += dx;
        } else {
          dx = (event.clientX - newPosition.x1)// / currentZoom;
          newPosition.x1 += dx;
        }
        if (newPosition.y1 > newPosition.y2) {
          dy = (event.clientY - newPosition.y2)// / currentZoom;
          newPosition.y2 += dy;
        } else {
          dy = (event.clientY - newPosition.y1)// / currentZoom;
          newPosition.y1 += dy;
        }
        break;
      case 'bottomLeft':
        if (newPosition.x1 > newPosition.x2) {
          dx = (event.clientX - newPosition.x2) / zoom.current;
          newPosition.x2 += dx;
        } else {
          dx = (event.clientX - newPosition.x1) / zoom.current;
          newPosition.x1 += dx;
        }
        if (newPosition.y2 > newPosition.y1) {
          dy = (event.clientY - newPosition.y2) / zoom.current;
          newPosition.y2 += dy;
        } else {
          dy = (event.clientY - newPosition.y1) / zoom.current;
          newPosition.y1 += dy;
        }
        break;
      case 'bottomRight':
        if (newPosition.x2 > newPosition.x1) {
          dx = (event.clientX - newPosition.x2) / zoom.current;
          newPosition.x2 += dx;
        } else {
          dx = (event.clientX - newPosition.x1) / zoom.current;
          newPosition.x1 += dx;
        }
        if (newPosition.y2 > newPosition.y1) {
          dy = (event.clientY - newPosition.y2) / zoom.current;
          newPosition.y2 += dy;
        } else {
          dy = (event.clientY - newPosition.y1) / zoom.current;
          newPosition.y1 += dy;
        }
        break;
      default:
        break;
    }
    setSelectedPathologyPrediction(prev => ({
      ...prev,
      position: newPosition
    }));
  };

  // Logical constants
  const shouldDisplayLabels = () => showLabels && pathologyPredictionsWithPosition;
  const getPredictionsGroupedByPathology = () => Object.entries(groupBy(pathologyPredictionsWithPosition, ({pathology}) => pathology.name));
  const deleteModalShouldRender = () => selectedPathologyPrediction && selectedPathologyPredictionDeleteConfirmation;

  const getMmLinePosition = (x1, x2) => {
    const diff = (Math.abs(x2 - x1)/2 + 20);
    return x1 > x2 ? x2 + diff : x1 + diff;
  }

  const getBadgeIdealPosition = (selectedPrediction) => {
    let tempTop = selectedPrediction.position.y2 * zoom.current + 10 + (translateY | 0);
    let tempLeft = selectedPrediction.position.x2 * zoom.current + 10  + (translateX | 0);
    if(svgRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      tempTop = tempTop > rect.height - 20 ? selectedPrediction.position.y1 * zoom.current - 10 + (translateY | 0) : tempTop;
      tempLeft = tempLeft > rect.width - 80 ? selectedPrediction.position.x1 * zoom.current - 80 + (translateX | 0) : tempLeft;
    }
    return {
      top: tempTop,
      left: tempLeft,
    }
  }

  return (
    <Row className="h-100" draggable={draggable} onMouseMove={handleDrag} onMouseUp={handleDragEnd} onMouseDown={handleDragStart} 
      onTouchStart={(event) => {
        const touch = event.touches[0] || event.changedTouches[0];
        const updatedEvent = {...event, clientX: touch.clientX, clientY: touch.clientY}
        handleDragStart(updatedEvent)}} 
    onTouchMove={(event) => {
      event.stopPropagation();
      const touch = event.touches[0] || event.changedTouches[0];
      const updatedEvent = {...event, clientX: touch.clientX, clientY: touch.clientY}
      handleDrag(updatedEvent)}} 
    onTouchEnd={(event) => {
      handleDragEnd()}} >
      {imageUrl && forceRender !== null && <Col md={shouldDisplayLabels() ? "8" : "12"} className={`d-flex justify-content-center align-items-center`}>
        <div className="position-relative" style={{width:hideContainerWidth.current, height:hideContainerHeight.current}}>
          <div className="overflow-hidden w-100 h-100 position-relative m-1">
            {imageEntity && (
              <img
                id={`image-${imageEntity.id}`}
                src={imageUrl}
                ref={imageRef}
                width={getImageWidth(zoom.current)}
                height={getImageHeight(zoom.current)}
                style={{ filter: `brightness(${brightness}%) contrast(${contrast}%)`, zIndex: '0', transform: `translate(${translateX}px, ${translateY}px)` }}
                className="position-absolute top-0 left-0"
              />
            )}
            <svg
              ref={svgRef}
              id={`svg-${imageEntity.id}`}
              style={{ transformOrigin: 'top left', transform: `translate(${translateX}px, ${translateY}px) scale(${zoom.current})` }}
              width={imageEntity.width ? `${imageEntity.width}px` : '1000px'}
              height={imageEntity.height ? `${imageEntity.height}px` : '1000px'}
              className="position-absolute top-0 left-0"
              onMouseDown={(event) => {
                setSelectedPathologyPrediction(null);
                handleClick(event, zoom.current, selectedPathologyPrediction);
              }}
              onTouchStart={(event) => {
                event.stopPropagation();
                document.body.classList.add('no-scroll');
                const touch = event.touches[0] || event.changedTouches[0];
                const updatedEvent = {...event, clientX: touch.clientX, clientY: touch.clientY}
                setSelectedPathologyPrediction(null);
                handleClick(updatedEvent, zoom.current, selectedPathologyPrediction);
              }}
              onMouseUp={(event) => {
                handleClick(event, zoom.current, selectedPathologyPrediction)
              }}
              onTouchEnd={(event) => {
                document.body.classList.remove('no-scroll');
                const touch = event.touches[0] || event.changedTouches[0];
                const updatedEvent = {...event, clientX: touch.clientX, clientY: touch.clientY}
                handleClick(updatedEvent, zoom.current, selectedPathologyPrediction)
              }}
              onMouseMove={(event) => {
                handleMouseMove(event);
              }}
              onTouchMove={(event) => {
                event.stopPropagation();
                const touch = event.touches[0] || event.changedTouches[0];
                const updatedEvent = {...event, clientX: touch.clientX, clientY: touch.clientY}
                handleMouseMove(updatedEvent);
              }}
            >
              <mask id="selectedMask">
                <rect x="0" y="0" width="100%" height="100%" fill="white" />
                {startCoords && endCoords && <rect 
                  x={Math.min(startCoords.x, endCoords.x) / zoom.current}
                  y={Math.min(startCoords.y, endCoords.y) / zoom.current}
                  width={Math.abs(endCoords.x / zoom.current - startCoords.x / zoom.current)}
                  height={Math.abs(endCoords.y / zoom.current - startCoords.y / zoom.current)}
                  fill="black" />}
              </mask>
              { pathologyPredictionsWithPosition?.map(prediction => {
                if (prediction.position) {
                  const position = prediction.position;
                  const boxWidth = Math.abs(position.x1 - position.x2);
                  const boxHeight = Math.abs(position.y1 - position.y2);
                  return (
                    <React.Fragment key={prediction.id}>
                      {selectedPathologyPrediction?.id !== prediction.id && (position.type === 'rect' || position.type === 'label') && (
                        <rect
                          x={Math.min(position.x1, position.x2)}
                          y={Math.min(position.y1, position.y2)}
                          width={boxWidth}
                          height={boxHeight}
                          style={fillColor(prediction?.pathology, "stroke")}
                          fill="transparent"
                          strokeWidth={selectedPathologyPrediction?.id === prediction.id ? '7' : '2'}
                          data-id={prediction.id}
                          onClick={(event) => handleUpdateSelectedPatho(event, prediction)}
                        />
                      )}
                      {position.type === 'line' && (
                        <line
                          x1={position.x1}
                          y1={position.y1}
                          x2={position.x2}
                          y2={position.y2}
                          style={fillColor(prediction?.pathology, "stroke")}
                          fill="transparent"
                          strokeWidth={selectedPathologyPrediction?.id === prediction.id ? '7' : '2'}
                          data-id={prediction.id}
                          onClick={(event) => handleUpdateSelectedPatho(event, prediction)}
                          onTouchStart={(event) => {
                            const touch = event.touches[0] || event.changedTouches[0];
                            const updatedEvent = {...event, clientX: touch.clientX, clientY: touch.clientY}
                            handleUpdateSelectedPatho(updatedEvent, prediction)}}
                        />
                      )}
                      {prediction.teeth &&
                        <text fontSize="large" x={(position.x1 + 20)} y={(position.y1 - 20)} style={fillColor(prediction.pathology, "fill")}>
                          {prediction.teeth}
                        </text>
                      }
                      {prediction.pathology.name.toLowerCase().includes("periodontic") && prediction.mmDistance && position.type === 'line' &&
                        <text fontSize="large" x={getMmLinePosition(position.x1, position.x2)} y={(getMmLinePosition(position.y1, position.y2))} style={fillColor(prediction.pathology, "fill")}>
                          {prediction.mmDistance}mm
                        </text>
                      }
                      {prediction.pathology.name.toLowerCase().includes("periodontic") && prediction.mmDistance && position.type === 'rect' &&
                        <text fontSize="large" x={(position.x2 > position.x1 ? position.x2 : position.x1 + 20)} y={getMmLinePosition(position.y1, position.y2)} style={fillColor(prediction.pathology, "fill")}>
                          {prediction.mmDistance}mm
                        </text>
                      }
                      
                    </React.Fragment>
                  );
                }
                return null;
              })}
              {(selectedPathologyPrediction?.position?.type === "rect" || selectedPathologyPrediction?.position?.type === "label") && 
                <rect x="0" y="0" width="100%" height="100%" opacity="0.7" fill="grey" mask="url(#selectedMask)"/>
              }
              {rulerSelected && startCoords && endCoords && (
                <line x1={startCoords.x / zoom.current} y1={startCoords.y / zoom.current} x2={endCoords.x / zoom.current} y2={endCoords.y / zoom.current} stroke="white" strokeWidth="2" />
              )}
      
              {(bboxSelected || labelBoxSelected) && startCoords && endCoords && (
                <rect
                  x={Math.min(startCoords.x, endCoords.x) / zoom.current}
                  y={Math.min(startCoords.y, endCoords.y) / zoom.current}
                  width={Math.abs(endCoords.x / zoom.current - startCoords.x / zoom.current)}
                  height={Math.abs(endCoords.y / zoom.current - startCoords.y / zoom.current)}
                  fill="transparent"
                  stroke="white"
                  strokeWidth="2"
                />)}
                {(selectedPathologyPrediction?.position.type === "rect" || selectedPathologyPrediction?.position.type === "label") && startCoords && endCoords && (
                <rect className="movable-rect"
                  x={Math.min(startCoords.x, endCoords.x) / zoom.current}
                  y={Math.min(startCoords.y, endCoords.y) / zoom.current}
                  width={Math.abs(endCoords.x / zoom.current - startCoords.x / zoom.current)}
                  height={Math.abs(endCoords.y / zoom.current - startCoords.y / zoom.current)}
                  fill="transparent"
                  stroke={getColor(selectedPathologyPrediction?.pathology)}
                  strokeWidth={4/zoom.current}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                    onPathologyPredictionDragBoxClick(event, zoom.current);
                  }}
                  onTouchStart={(event) => {
                    document.body.classList.add('no-scroll');
                    event.stopPropagation();
                    const touch = event.touches[0] || event.changedTouches[0];
                    const updatedEvent = {...event, clientX: touch.clientX, clientY: touch.clientY}
                    onPathologyPredictionDragBoxClick(updatedEvent, zoom.current);}}
                  onTouchEnd={(event) => {
                    document.body.classList.remove('no-scroll');
                  }}
                />)}
                {selectedPathologyPrediction?.id && startCoords && endCoords && <React.Fragment>
                <circle
                className="expand-circle-pin-nwse"
                cx={Math.min(startCoords.x, endCoords.x) / zoom.current}
                cy={Math.min(startCoords.y, endCoords.y) / zoom.current}
                r={6/zoom.current}
                fill={getColor(selectedPathologyPrediction?.pathology)}
                onMouseDown={(event) => {
                  event.stopPropagation();
                  onPathologyPredictionDragPinClick(event, 'topLeft', zoom.current);
                }}
                onTouchStart={(event) => {
                  document.body.classList.add('no-scroll');
                  event.stopPropagation();
                  const touch = event.touches[0] || event.changedTouches[0];
                  const updatedEvent = {...event, clientX: touch.clientX, clientY: touch.clientY}
                  onPathologyPredictionDragPinClick(updatedEvent, 'topLeft', zoom.current);}}
                onTouchEnd={(event) => {
                  document.body.classList.remove('no-scroll');
                }}
                />
                <circle
                  className="expand-circle-pin-nesw"
                  cx={Math.max(startCoords.x, endCoords.x) / zoom.current}
                  cy={Math.min(startCoords.y, endCoords.y) / zoom.current}
                  r={6/zoom.current}
                  fill={getColor(selectedPathologyPrediction?.pathology)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                    onPathologyPredictionDragPinClick(event, 'topRight', zoom.current);
                  }}
                  onTouchStart={(event) => {
                    document.body.classList.add('no-scroll');
                    event.stopPropagation();
                    const touch = event.touches[0] || event.changedTouches[0];
                    const updatedEvent = {...event, clientX: touch.clientX, clientY: touch.clientY}
                    onPathologyPredictionDragPinClick(updatedEvent, 'topRight', zoom.current);}}
                    onTouchEnd={(event) => {
                      document.body.classList.remove('no-scroll');
                    }}                />
                <circle
                  className="expand-circle-pin-nesw"
                  cx={Math.min(startCoords.x, endCoords.x) / zoom.current}
                  cy={Math.max(startCoords.y, endCoords.y) / zoom.current}
                  r={6/zoom.current}
                  fill={getColor(selectedPathologyPrediction?.pathology)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                    onPathologyPredictionDragPinClick(event, 'bottomLeft', zoom.current)
                  }}
                  onTouchStart={(event) => {
                    document.body.classList.add('no-scroll');
                    event.stopPropagation();
                    const touch = event.touches[0] || event.changedTouches[0];
                    const updatedEvent = {...event, clientX: touch.clientX, clientY: touch.clientY}
                    onPathologyPredictionDragPinClick(updatedEvent, 'bottomLeft', zoom.current);}}
                    onTouchEnd={(event) => {
                      document.body.classList.remove('no-scroll');
                    }}                />
                <circle
                  className="expand-circle-pin-nwse"
                  cx={Math.max(startCoords.x, endCoords.x) / zoom.current}
                  cy={Math.max(startCoords.y, endCoords.y) / zoom.current}
                  r={6/zoom.current}
                  fill={getColor(selectedPathologyPrediction?.pathology)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                    onPathologyPredictionDragPinClick(event, 'bottomRight', zoom.current)
                  }}
                  onTouchStart={(event) => {
                    document.body.classList.add('no-scroll');
                    event.stopPropagation();
                    const touch = event.touches[0] || event.changedTouches[0];
                    const updatedEvent = {...event, clientX: touch.clientX, clientY: touch.clientY}
                    onPathologyPredictionDragPinClick(updatedEvent, 'bottomRight', zoom.current);}}
                    onTouchEnd={(event) => {
                      document.body.classList.remove('no-scroll');
                    }}                />
              </React.Fragment>}
            </svg>
          </div>
          
          { selectedPathologyPrediction?.id && (
            <div
              className="position-absolute pt-0 pb-0 badge border border-white"
              style={{ ...getBadgeIdealPosition(selectedPathologyPrediction),
                color:'black', width:"auto", ...fillColor(selectedPathologyPrediction.pathology, "background-color") }}
            >
                <span className="me-2 text-black fw-light">{tempPredictionToRender?.pathology?.name && translate(`dfaiApp.pathology.${tempPredictionToRender?.pathology?.name}`)}</span>
                <Button className="heroicon-button" onClick={() => onEditClick(selectedPathologyPrediction)}>
                  <PencilIcon className="small-heroicon mb-1" />
                </Button>
                {canEdit() && <Button className="heroicon-button" onClick={() => setSelectedPathologyPredictionDeleteConfirmation(true)}>
                  <TrashIcon className="small-heroicon mb-1" />
                </Button>}
            </div>
          )}
        </div>
      </Col>}
      { shouldDisplayLabels() && 
      <Col md="4" className={`h-100 ${isMobileOnly ? "mobile-image-view-show-label-container" : ""} force`} style={{overflowY: "auto"}}>
        {getPredictionsGroupedByPathology().map(([pathologyName, subPredictions]) => (
          <div key={`pathology_${pathologyName}`}>
            <p>
            <span className="mx-2"><CircleIcon color={colorPathologyMap.hasOwnProperty(pathologyName) ? colorPathologyMap[pathologyName] : "white"}/></span>
              <b>
                <Translate contentKey={`dfaiApp.pathology.${pathologyName}`}/>
              </b>
            </p>
            {subPredictions.map((prediction, index) => (
              <div key={`prediction_${index}`}>
                <p style={{color: "grey"}}>
                  {prediction.teeth && 
                      <span><Translate contentKey="dfaiApp.pathologyPrediction.teeth"/> {prediction.teeth}</span>}
                    {prediction.teeth && " - "}
                    {prediction.annotation && `${prediction.annotation}`}
                </p>
              </div>
            ))}
          </div>))}
      </Col>}
      { deleteModalShouldRender() && (
        <Modal isOpen={true} toggle={() => setSelectedPathologyPredictionDeleteConfirmation(false)} centered>
          <ModalHeader toggle={() => setSelectedPathologyPredictionDeleteConfirmation(false)}>Confirmation</ModalHeader>
          <ModalBody>
            <h3 className="text-lg text-center">Are you sure you want to delete?</h3>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={handleDelete}>
              <FontAwesomeIcon icon={faTrash} /> Delete
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Row>
  );
};

export default ImageViewer;
