import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { IQueryParams, createEntitySlice, EntityState } from 'app/shared/reducers/reducer.utils';
import { defaultValue } from 'app/shared/model/quote.model';
import { IMatching } from 'app/shared/model/matching.model';

const initialState: EntityState<IMatching> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  overview: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

const apiUrl = 'api/matching';

// Actions

export const getEntities = createAsyncThunk('quote/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<IMatching[]>(requestUrl);
});

// slice

export const MatchingSlice = createEntitySlice({
  name: 'matching',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
  },
});

export const { reset } = MatchingSlice.actions;

// Reducer
export default MatchingSlice.reducer;
