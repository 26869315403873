import { IPathologyPrediction } from 'app/shared/model/pathology-prediction.model';

export interface IPathology {
  id?: string;
  name?: string | null;
  recommendation?: string | null;
  reportRecommendation?: string | null;
  aiLabels?: string[] | null;
  aiXrayTreshold?: number | null;
  aiPanoramicTreshold?: number | null;
  aiPhotoTreshold?: number | null;
}

export const defaultValue: Readonly<IPathology> = {};
