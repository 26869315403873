import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Patient from './patient';
import Professional from './professional';
import Clinic from './clinic';
import PredictionFilter from './prediction-filter';
import Matching from './quote';
import Act from './act';
import ScrapingConfig from './scraping-config';
import Image from './patient/consultation/image';
import ImageDiagnostic from './patient/consultation/image/image-diagnostic';
import Pathology from './pathology';
import QuoteAnnotation from './quote-annotation';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="patient/*" element={<Patient />}/>
        <Route path="professional/*" element={<Professional />} />
        <Route path="clinic/*" element={<Clinic />} />
        <Route path="prediction-filter/*" element={<PredictionFilter />} />
        <Route path="matching/*" element={<Matching />} />
        <Route path="act/*" element={<Act />} />
        <Route path="scraping-config/*" element={<ScrapingConfig />} />
        <Route path="image/*" element={<Image />} />
        <Route path="image-diagnostic/*" element={<ImageDiagnostic />} />
        <Route path="pathology/*" element={<Pathology />} />
        <Route path="quote-annotation/*" element={<QuoteAnnotation />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
