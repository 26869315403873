import React from 'react';

export const PatientApp = () => {

  return (
    <div>
      PATIENT APP
    </div>
  );
};

export default PatientApp;
