import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faEye, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getConsultationEntities as getConsultationQuotes, createEntity as uploadQuote, deleteEntity as deleteQuote } from './quote.reducer';
import { useDropzone } from 'react-dropzone';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useParams } from 'react-router';
import { Translate } from 'react-jhipster';

const QuoteManager = () => {

const { patientId, consultationId } = useParams();

  const dispatch = useAppDispatch();
  const quotes = useAppSelector(state => state.quote.entities);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const loading = useAppSelector(state => state.consultation.loading);
  const quoteAudioRef = useRef(null);
  useEffect(() => {
    dispatch(getConsultationQuotes({patientId, consultationId}));
  }, []);

  useEffect(() => {
  }, [quotes]);

  const onDrop = useCallback(async acceptedFiles => {
    if (acceptedFiles.length === 1) {
      handleFileUpload(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    dispatch(uploadQuote({patientId, consultationId, formData}));
    if (quoteAudioRef.current) {
      quoteAudioRef.current.play();
    }
  };

  const handleViewQuote = (quote) => {
    window.open(`api/patients/${patientId}/consultations/${consultationId}/quotes/${quote.id}/file`, '_blank');
  };

  const handleDeleteQuote = (quote) => {
    setSelectedQuote(quote);
    setDeleteModal(true);
  };

  const confirmDelete = () => {
    dispatch(deleteQuote({patientId, consultationId, quoteId: selectedQuote.id}));
    setDeleteModal(false);
    setSelectedQuote(null);
  };

  return (
    <div>
      <h2 data-cy="imageDetailsHeading">
        <Translate contentKey="dfaiApp.quote.home.title">Quotes</Translate>
      </h2>
      <audio ref={quoteAudioRef} src="content/audio/devissend.mp3" />
      <div className="list-group mt-4">
        {quotes && quotes.length > 0 ? quotes.map(quote => (
          <div key={quote.id} className="list-group-item d-flex flex-column justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div>
                <FontAwesomeIcon icon={faFilePdf} className="text-danger me-2" />
              </div>
              <div>
                <Button color="info" onClick={() => handleViewQuote(quote)} className="me-2">
                  <FontAwesomeIcon icon={faEye} />
                </Button>
                <Button color="danger" onClick={() => handleDeleteQuote(quote)} className="me-2">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            </div>
            <div>
              {quote.originalName ? quote.originalName : quote.sourceFileName}
            </div>
          </div>
        )) : (
          <Translate contentKey="dfaiApp.quote.missing">Budget missing</Translate>
        )}
      </div>
      <div
        {...getRootProps()}
        className="border border-dashed border-primary text-center py-4"
        style={{ cursor: 'pointer' }}
      >
        <input {...getInputProps()} />
        <FontAwesomeIcon icon={faUpload} className="text-primary fs-1" />
        <Translate contentKey="dfaiApp.quote.uploadTooltip">Drop files here to upload</Translate>
      </div>
      <Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)}>
        <ModalHeader toggle={() => setDeleteModal(!deleteModal)}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this quote?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmDelete}>Delete</Button>{' '}
          <Button color="secondary" onClick={() => setDeleteModal(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default QuoteManager;
