import { IQuote } from 'app/shared/model/quote.model';

export interface IQuoteAnnotation {
  id?: string;
  label?: string | null;
  content?: string | null;
  quote?: IQuote | null;
}

export const defaultValue: Readonly<IQuoteAnnotation> = {};
