import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IImage, defaultValue } from 'app/shared/model/image.model';

const initialState: EntityState<IImage> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  overview: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

const apiUrl = 'api/images';

// Actions

export const getEntities = createAsyncThunk('image/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<IImage[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'image/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IImage>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const rotateImage = createAsyncThunk(
  'image/rotate-image',
  async ({patientId, consultationId, imageId, rotation}: {patientId: string; consultationId: string; imageId: string; rotation: number}) => {
    const requestUrl = `api/patients/${patientId}/consultations/${consultationId}/images/${imageId}/rotate?rotation=${rotation}`;
    const result = await axios.get(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const getConsultationImages = createAsyncThunk(
  'image/fetch_consultation_entities',
  async ({patientId, consultationId}: {patientId: string; consultationId: string}) => {
    const requestUrl = `api/patients/${patientId}/consultations/${consultationId}/images`;
    return axios.get<IImage[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'image/create_entity',
  async (entity: IImage, thunkAPI) => {
    const result = await axios.post<IImage>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'image/update_entity',
  async (entity: IImage, thunkAPI) => {
    const result = await axios.put<IImage>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'image/partial_update_entity',
  async ({patientId, consultationId, entity}: {patientId: string; consultationId: string; entity:IImage}, thunkAPI) => {
    const result = await axios.patch<IImage>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getConsultationImages({patientId, consultationId}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'image/delete_entity',
  async ({patientId, consultationId, imageId}: {patientId: string; consultationId: string; imageId: string}, thunkAPI) => {
    const requestUrl = `${apiUrl}/${imageId}`;
    const result = await axios.delete<IImage>(requestUrl);
    if (patientId && consultationId) {
      thunkAPI.dispatch(getConsultationImages({patientId, consultationId}));
    } else {
      thunkAPI.dispatch(getEntities({}));
    }
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const ImageSlice = createEntitySlice({
  name: 'image',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(rotateImage.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(getConsultationImages.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getConsultationImages), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ImageSlice.actions;

// Reducer
export default ImageSlice.reducer;
