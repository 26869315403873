import './header.scss';

import React, { useEffect, useState } from 'react';
import { Translate, Storage, translate } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Brand } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../menus';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import { LeftMenu } from '../menus/menu-left';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import Professional from 'app/entities/professional/professional';
import { ProfessionalStatus } from 'app/shared/model/enumerations/professional-status.model';
import { getLoggedProfessional } from 'app/shared/reducers/authentication';
import { isMobile } from 'react-device-detect';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
  authorities: string[];
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [username, setUsername] = useState(null);

  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const authentication = useAppSelector(state => state.authentication);
  const account = useAppSelector(state => state.authentication.account);
  const loggedProfessional = useAppSelector(state => state.authentication.account.loggedProfessional);

  useEffect(() => {
    setUsername(account.login);
    if(isAuthenticated) {
      dispatch(getLoggedProfessional());
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (loggedProfessional?.status) {
      const doctorStatus = [ProfessionalStatus.LIBERAL_COLLABORATOR, ProfessionalStatus.SALARIED_COLLABORATOR, ProfessionalStatus.SUBSTITUTE];
      setUsername(`${loggedProfessional.status.some(s => doctorStatus.includes(s)) ? "Dr." : "" } ${loggedProfessional.firstName} ${loggedProfessional.lastName}`)
    }
  }, [loggedProfessional])
  
  const handleLocaleChange = event => {
    toggleMenu();
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  const closeMenu = () => setMenuOpen(false);

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <div id="app-header">
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      <Navbar data-cy="navbar" dark expand="md" fixed="top" full className="jh-navbar">
        <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        <Brand />
        {props.isAuthenticated && 
          <div>
            <p className="text-white mb-0 mt-1">{!isMobile && `${translate("global.menu.auth.logged")} : `}{username}</p>
          </div>
        }
        <Collapse isOpen={menuOpen} navbar>
          <Nav id="header-tabs" className="ms-auto" navbar>
            {props.isAuthenticated && <LeftMenu authorities={props.authorities} onClick={closeMenu}/>}
            {props.isAuthenticated && props.isAdmin && <EntitiesMenu />}
            {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled}/>}
            <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
            <AccountMenu isAuthenticated={props.isAuthenticated} />
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
