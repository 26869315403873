import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity, updateEntity, createEntity, reset } from './pathology.reducer';

export const PathologyUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const pathologyEntity = useAppSelector(state => state.pathology.entity);
  const loading = useAppSelector(state => state.pathology.loading);
  const updating = useAppSelector(state => state.pathology.updating);
  const updateSuccess = useAppSelector(state => state.pathology.updateSuccess);

  const [aiLabels, setAiLabels] = useState<string[]>(pathologyEntity.aiLabels || ['']);

  const handleClose = () => {
    navigate('/pathology' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (!isNew && pathologyEntity.aiLabels) {
      setAiLabels([...pathologyEntity.aiLabels, '']);
    }
  }, [pathologyEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const handleAiLabelChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newAiLabels = [...aiLabels];
    newAiLabels[index] = event.target.value;
    setAiLabels(newAiLabels);

    if (index === aiLabels.length - 1 && event.target.value !== '') {
      setAiLabels([...newAiLabels, '']);
    }
  };

  const handleAiLabelBlur = (index: number) => {
    if (aiLabels[index] === '' && index !== aiLabels.length - 1) {
      setAiLabels(aiLabels.filter((_, i) => i !== index));
    }
  };

  const saveEntity = values => {
    const entity = {
      ...pathologyEntity,
      ...values,
      aiLabels: aiLabels.filter(label => label !== ''),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...pathologyEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dfaiApp.pathology.home.createOrEditLabel" data-cy="PathologyCreateUpdateHeading">
            <Translate contentKey="dfaiApp.pathology.home.createOrEditLabel">Create or edit a Pathology</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="pathology-id"
                  label={translate('dfaiApp.pathology.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('dfaiApp.pathology.name')} id="pathology-name" name="name" data-cy="name" type="text" />
              <ValidatedField
                label={translate('dfaiApp.pathology.aiXrayTreshold')}
                id="pathology-aiXrayTreshold"
                name="aiXrayTreshold"
                data-cy="aiXrayTreshold"
                type="number"
                step="any"
              />
              <ValidatedField
                label={translate('dfaiApp.pathology.aiPanoramicTreshold')}
                id="pathology-aiPanoramicTreshold"
                name="aiPanoramicTreshold"
                data-cy="aiPanoramicTreshold"
                type="number"
                step="any"
              />
              <ValidatedField
                label={translate('dfaiApp.pathology.aiPhotoTreshold')}
                id="pathology-aiPhotoTreshold"
                name="aiPhotoTreshold"
                data-cy="aiPhotoTreshold"
                type="number"
                step="any"
              />
              <div>Pour changer la recommandation du rapport, modifier les fichiers de traduction back dans "dfai\src\main\resources\i18n\messages_(locale).properties" en changeant report.pathology.recommendation.{pathologyEntity.name}</div>
              <div>
                <label>
                  <Translate contentKey="dfaiApp.pathology.aiLabels">AI Labels</Translate>
                </label>
                {aiLabels.map((label, index) => (
                  <div key={index} className="d-flex">
                    <input
                      type="text"
                      className="form-control"
                      value={label}
                      onChange={event => handleAiLabelChange(index, event)}
                      onBlur={() => handleAiLabelBlur(index)}
                    />
                  </div>
                ))}
              </div>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/pathology" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PathologyUpdate;
