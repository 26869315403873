import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Image from './image';
import ImageDetail from './image-detail';
import ImageUpdate from './image-update';
import ImageDeleteDialog from './image-delete-dialog';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

const ImageRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Image />} />
    <Route path="new" element={<ImageUpdate />} />
    <Route path=":id">
      <Route index element={<ImageDetail />} />
      <Route path="edit" element={
        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.UPDATE_CONSULTATION]}>
          <ImageUpdate />
        </PrivateRoute>} />
      <Route path="delete" element={
        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.UPDATE_CONSULTATION]}>
          <ImageDeleteDialog />
        </PrivateRoute>} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ImageRoutes;
