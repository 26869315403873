import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Patient from './patient';
import PatientDetail from './patient-detail';
import PatientUpdate from './patient-update';
import PatientDeleteDialog from './patient-delete-dialog';
import Consultation from './consultation';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';


const PatientRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Patient />} />
    <Route path="new" element={<PatientUpdate />} />
    <Route path=":patientId">
      <Route index element={<PatientDetail />} />
      <Route path="edit" element={
        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.CREATE_PATIENT, AUTHORITIES.UPDATE_PATIENT]}>
          <PatientUpdate />
        </PrivateRoute>
      }/>
      
      <Route path="delete" element={
        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.UPDATE_PATIENT]}>
          <PatientDeleteDialog />
        </PrivateRoute>
      } />
      <Route path="consultation/*" element={
        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.VIEW_PATIENT_DETAILS]}>
          <Consultation />
        </PrivateRoute>
      } />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PatientRoutes;
